import React from "react";
import { useEffect } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";

export default function AboutUsCard() {
  const ourJourney = require("../../../../src/assets/images/about/Our-Journey.png");
  const ourReach = require("../../../../src/assets/images/about/Our-Reach.png");
  const ourVision = require("../../../../src/assets/images/about/Our-Vision.png");

  const clientFocus = require("../../../../src/assets/images/about/Client-Focus.png");
  const integrity = require("../../../../src/assets/images/about/Integrity.png");
  const innovation = require("../../../../src/assets/images/about/Innovation.png");
  const teamWork = require("../../../../src/assets/images/about/Team-Work.png");

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div id="aboutUsCard" className="row">
      <div
        className="col-lg-4 col-md-6 d-flex align-items-stretch"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div className="icon-box-about iconbox-blue-about">
          <img src={ourJourney} className="img-fluid" alt="Krill Journey" />
          <h4>
            <span>Our Journey</span>
          </h4>
          <p>
            Over 200+ satisfied clients with 90% retention within 4 years. An
            expert team of over 85 members. We have experienced 2 fold growth
            annually since inception
          </p>
        </div>
      </div>

      <div
        className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
        data-aos="zoom-in"
        data-aos-delay="200"
      >
        <div className="icon-box-about iconbox-orange-about">
          <img src={ourReach} className="img-fluid" alt="Krill Reach" />
          <h4>
            <span>Our Reach</span>
          </h4>
          <p>
            We have profiled over 8 million target audiences across all business
            verticals on 4 continents. Well-versed in understanding the nuance
            of ITDM's and their buying journey.
          </p>
        </div>
      </div>

      <div
        className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
        data-aos="zoom-in"
        data-aos-delay="300"
      >
        <div className="icon-box-about iconbox-pink-about">
          <img src={ourVision} className="img-fluid" alt="Krill Vision" />
          <h4>
            <span>Our Vision</span>
          </h4>
          <p>
            To be amongst the 5 most admired B2B media agencies with a
            leadership focus on improving the ROI on IT demand generation
            expenditure.
          </p>
        </div>
      </div>
      <div className="mobile-margin visible-xs"></div>
      <h1>Our Values</h1>
      <p>
        Our core values serve as the foundation for all of our actions and
        choices
      </p>
      <div
        className="col-lg-3 col-md-6 d-flex align-items-stretch"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div className="icon-box-about iconbox-blue-about">
          <img src={clientFocus} className="img-fluid" alt="Client Focus" />
          <h4>
            <span>Client Focus</span>
          </h4>
          <p>
            We are dedicated to giving our clients services that go above and
            beyond their expectations and that cater to their specific
            requirements. Our team pays close attention to what our clients have
            to say so that we can completely comprehend their needs and provide
            solutions that are suited to them.
          </p>
        </div>
      </div>

      <div
        className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
        data-aos="zoom-in"
        data-aos-delay="200"
      >
        <div className="icon-box-about iconbox-orange-about">
          <img src={integrity} className="img-fluid" alt="Integrity" />
          <h4>
            <span>Integrity</span>
          </h4>
          <p>
            We are committed to operating with honesty and ethics. We work hard
            to establish lasting relationships with our clients by providing
            high-quality services, upholding transparency, and abiding by moral
            principles.
          </p>
        </div>
      </div>

      <div
        className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
        data-aos="zoom-in"
        data-aos-delay="300"
      >
        <div className="icon-box-about iconbox-pink-about">
          <img src={innovation} className="img-fluid" alt="Innovation" />
          <h4>
            <span>Innovation</span>
          </h4>
          <p>
            To remain on top of the game, we believe in innovation and constant
            improvement. In order to provide better results for our clients, our
            team is constantly looking for new methods to enhance our services
            and procedures.
          </p>
        </div>
      </div>
      <div
        className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
        data-aos="zoom-in"
        data-aos-delay="300"
      >
        <div className="icon-box-about iconbox-pink-about">
          <img src={teamWork} className="img-fluid" alt="Team Work" />
          <h4>
            <span>Teamwork</span>
          </h4>
          <p>
            To accomplish our objectives, we think it's important to work
            together. Our team is made up of experts from various backgrounds,
            and we use their skills and knowledge to provide our clients with
            top-notch services.
          </p>
        </div>
      </div>
    </div>
  );
}
