import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function EmailMarketing({ showDetails }) {
  const video = require("../../../../../assets/images/services/Email_Marketing.png");

  const [show, setShow] = useState(showDetails);
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section
      id="email-marketing"
      className="about-video section-style-40 email-marketing-bg"
    >
      <HelmetProvider>
        <Helmet>
          <title>Email Marketing</title>
          <meta
            name="description"
            content="Businesses may effectively reach their clients, advertise their goods and services, and boost sales by using email marketing."
          />
          <meta
            name="keywords"
            content="Email marketing, Email campaigns, Email automation, Email marketing services, Email marketing software, Email newsletter, Email list building, Email design, Email personalization, Email segmentation, Email analytics, Email open rates, Click-through rates, Email deliverability, Email subject lines, Email marketing strategy, Email marketing best practices, Drip campaigns, Email A/B testing, Email lead generation"
          />
        </Helmet>
      </HelmetProvider>
      <div className="mobile-margin visible-xs"></div>

      {show && <div className="services-details-top-padding"></div>}
      <div className="container" data-aos={"fade-up"}>
        <div className="row content">
          <h1>Email Marketing</h1>
          {show && (
            <p className="fst-italic">
              We believe businesses may effectively reach their clients,
              advertise their goods and services, and boost sales by using email
              marketing. Offering email marketing services to other businesses
              can be a successful business venture for a service provider.
              Working with us as an email marketing service provider has the
              following advantages:
            </p>
          )}
          <div
            className="col-lg-4 video-box align-self-baseline aboutMargin"
            data-aos={"fade-left"}
            data-aos-delay={"100"}
          >
            <img src={video} className="img-fluid" alt="Email Marketing" />
          </div>

          {show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-right"}
              data-aos-delay={"100"}
            >
              <ul>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Expertise and Experience:</b> We possess the knowledge and
                  experience necessary to plan and carry out efficient email
                  marketing campaigns. We are aware of the best practices for
                  email marketing, such as coming up with eye-catching subject
                  lines, making emails visually appealing, and making emails
                  responsive to mobile devices
                </li>
                <li>
                  <i className="bx bx-check-double"></i> <b>Economical:</b> By
                  outsourcing email marketing to us, businesses may save time,
                  money, and resources on staff hiring and training as well as
                  the price of email marketing tools and software
                </li>
                <li>
                  <i className="bx bx-check-double"></i>{" "}
                  <b>Customized Campaigns:</b> We can assist businesses in
                  developing customized email campaigns that speak directly to
                  the wants and desires of their customers. More engagement
                  rates, greater click-through rates, and eventually more sales,
                  can result from it
                </li>
                <li>
                  <i className="bx bx-check-double"></i>{" "}
                  <b>Reports and Analytics: </b>We offer thorough reporting and
                  analytics on the effectiveness of email campaigns. This
                  assists businesses in understanding what works and what
                  doesn't so they may enhance their subsequent campaigns by
                  making data-driven judgments
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Compliance and Privacy: </b>We make sure that client data
                  is handled safely and privately, and that email campaigns
                  adhere to laws like the CAN-SPAM Act, CASL, and GDPR
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Client Support: </b>We offer ongoing assistance to
                  businesses, assisting them in troubleshooting any problems and
                  enhancing their campaigns for better outcomes
                </li>
              </ul>
            </div>
          )}

          {!show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-right"}
              data-aos-delay={"100"}
            >
              <p>
                Our Email Marketing Services are the only thing you need! Our
                team of professionals can assist you in creating and
                implementing a specific email marketing strategy that is catered
                to your particular requirements and objectives. For optimum
                engagement and conversion, we use the most recent best practices
                to make sure that your emails are sent to the appropriate
                recipients at the appropriate times.
              </p>
              <p>
                Your open and click-through rates will likely rise significantly
                with the help of our email marketing services, and your total
                ROI will also likely improve. Our email marketing specialists
                can assist you in achieving your objectives, whether they
                involve promoting a new product, increasing website traffic, or
                simply raising brand recognition. Our email marketing services
                can help you advance your B2B marketing initiatives!
              </p>
              <Link className="btn-learn-more" to={`/services/email-marketing`}>
                <span>Know More</span>
              </Link>
            </div>
          )}

          {show && (
            <p data-aos={"fade-up"} data-aos-delay={"200"}>
              Overall, working with us as an email marketing service provider we
              can assist businesses in boosting sales, saving time and money,
              and enhancing client relationships
            </p>
          )}
        </div>
      </div>
    </section>
  );
}
