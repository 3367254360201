import React from "react";
import KrillFooter from "../../../../footer/KrillFooter";
import KrillHeader from "../../../../header/KrillHeader";
import ContentSyndication from "./ContentSyndication";

export default function ContentSyndicationPage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <ContentSyndication showDetails={true}></ContentSyndication>
      <KrillFooter></KrillFooter>
    </div>
  );
}
