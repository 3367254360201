import React from "react";
import KrillFooter from "../../footer/KrillFooter";
import KrillHeader from "../../header/KrillHeader";
import BlogSection from "./BlogSection";

export default function BlogSectionPage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <BlogSection></BlogSection>
      <KrillFooter></KrillFooter>
    </div>
  );
}
