import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";

export default function LeadGenerationDetails() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <div className="aboutMargin" data-aos={"fade-up"} data-aos-delay={"100"}>
        <h3>Our Lead Generation Strategy</h3>
        <p>
          In collaboration with you, we create a unique lead generation plan
          that takes into account your target market and organizational goal.
          Our spcialized team can help you to decide which channels are the most
          efficient for reaching your ideal clients and then develop a strategy
          to generate leads through those channels.
        </p>
        <p>
          To produce leads, we frequently combine inbound and outbound marketing
          strategies. The main objective of inbound marketing is to produce
          content that draws potential buyers to a company's website or social
          media sites. This can be done through blog entries, social media
          updates, and other forms of material that benefit prospective clients.
        </p>
        <p>
          On the other side, outbound marketing entails contacting potential
          clients directly. This can include cold calling, cold emailing, and
          other forms of outreach meant to attract potential clients' attention.
          We will employ a range of strategies to engage with potential
          customers and advance them through the sales funnel post they have
          been found. This can involve sending them relevant emails, giving them
          individualized information, and providing them with exclusive offers
          and discounts with engaging content.
        </p>
      </div>
      <div className="aboutMargin" data-aos={"fade-up"} data-aos-delay={"100"}>
        <h3>HQL (High-quality Lead Generation) </h3>
        <p>
          HQL involves locating potential consumers or clients for a business
          based on predetermined criteria. There are various advantages that
          Krill Technologies as a provider of HQL Services can deliver to your
          business's clients:
        </p>
        <ul data-aos="zoom-in" data-aos-delay="100">
          <li>
            <i className="bx bx-check-double"></i>
            <b>Time Saving: </b> This is one of the greatest advantages of
            partnering with us for our HQL services. You can concentrate on
            other crucial areas of your business by outsourcing the lead
            creation process.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Cost Effective: </b> You’ll will save money by outsourcing HQL
            services. You can rely on our experience and infrastructure to
            create leads instead of investing in internal resources.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>High-quality leads: </b> As a seasoned HQL service provider, our
            business can provide you and your clients with high-quality
            consented leads. We utilize cutting-edge lead & demand generation
            strategies targeting particular demographics and verticals.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Scalability: </b> Depending on the demands of your clients, it is
            simple to scale up or down your HQL services. We offer a tailored
            solution that satisfies our client’s demands, regardless of whether
            they require a lot or just a few leads.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Expertise: </b>
            Our team of professionals can provide insightful analysis and
            counsel to our clients on the most effective lead generation tactics
            for their companies. Our clients can gain from a more focused
            approach to lead creation by utilizing our knowledge. Overall, we
            can help other businesses save time and money while also giving them
            access to high-quality leads that can help them expand their
            businesses by offering our HQL services to them.
          </li>
        </ul>
      </div>
      <div className="aboutMargin" data-aos={"fade-up"} data-aos-delay={"100"}>
        <h3>SQL (Sales Qualified Leads) </h3>
        <p>
          Krill Technology as a supplier of Sales Qualified Leads (SQL) entails
          locating and screening potential clients for other businesses. The
          following are some advantages of collaborating with us:
        </p>
        <ul data-aos="zoom-in" data-aos-delay="100">
          <li>
            <i className="bx bx-check-double"></i>
            <b>More Efficient Sales Process: </b> By supplying top-notch leads,
            we assist other businesses in streamlining their sales procedures.
            They no longer need to spend time and money trying to find potential
            clients who might not be a good fit.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Increased Revenue: </b> By supplying a constant flow of qualified
            leads, our clients are free to concentrate on closing sales and
            boosting income. This could result in their firm making more money
            and expanding.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Customized marketing: </b>By comprehending the target market of
            our clients, we develop specialized lead generation campaigns that
            speak to the appropriate people at the appropriate moment. For our
            clients, this leads to higher conversion rates and more ROI.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Cost effective: </b>By hiring our business to handle your lead
            generation needs, our clients can save money. To create leads
            internally, they don't need to spend money on pricey marketing
            efforts or hire more staff.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Expertise: </b>As a specialized lead generation business, we
            possess the knowledge and experience necessary to find and develop
            high-quality leads. Our expertise in the field and tried-and-true
            tactics can help our clients.
          </li>
        </ul>
        <p data-aos={"fade-up"} data-aos-delay={"200"}>
          Overall, collaborating with us for Sales Qualified Leads we can
          benefit other businesses by boosting sales, streamlining their sales
          procedure, and saving money. Our knowledge and specialized services
          can assist our clients in achieving their business objectives and
          staying one step ahead of the competition.
        </p>
      </div>
      <div
        id="telemarketing"
        className="aboutMargin"
        data-aos={"fade-up"}
        data-aos-delay={"300"}
      >
        <h3>Tele Marketing: </h3>
        <p>
          As a tele marketing service provider, our company can offer a range of
          benefits to our Clients, such as:
        </p>
        <ul data-aos="zoom-in" data-aos-delay="100">
          <li>
            <i className="bx bx-check-double"></i>
            <b>Cost-effective lead generation: </b>
            When compared to conventional marketing techniques, telemarketing is
            a more affordable means to generate leads. Your team can target
            particular demographics and offer tailored sales messages, which
            could lead to a higher conversion rate and more revenue for our
            clients.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Enhanced consumer engagement: </b>
            Telemarketing gives you the chance to interact with customers
            directly and establish rapport. Clients happiness and loyalty will
            increase as a result of our team's ability to provide information
            and respond to inquiries.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Effective time management: </b>
            By overseeing clients telemarketing campaigns, our business can help
            them save time and money. This enables clients to maintain a
            constant presence in the market while concentrating on other aspects
            of their company.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Flexibility and scalability: </b>
            Telemarketing campaigns can easily be scaled up or down depending on
            the client's requirements. Our business can offer a flexible service
            that can adjust to market or clients business changes.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Detailed reporting and analytics: </b>
            We can give clients reports and analytics that are in-depth
            regarding the success of their telemarketing campaigns. Making
            data-driven decisions and improving future campaigns are both
            possible with this knowledge.
          </li>
        </ul>
        <p data-aos={"fade-up"} data-aos-delay={"200"}>
          Using our telemarketing services we can offer companies a
          cost-effective method to generate leads, interact with clients, and
          conserve time and resources. Our business can provide a flexible and
          scalable service that can adjust to clients changing requirements
          while offering thorough reporting and analytics to support their
          decision-making.
        </p>
      </div>
      <div
        id="appointment"
        className="aboutMargin"
        data-aos={"fade-up"}
        data-aos-delay={"300"}
      >
        <h3>Appointment Setting</h3>
        <p>
          Setting appointments is a useful service that businesses can use to
          develop leads, qualify prospects, and eventually boost sales. Working
          with us as an appointment-setting service supplier has several
          advantages. Our team of schedule setters has received extensive
          training in customer service, negotiation, and communication. We have
          a great deal of experience making cold calls, following up, and
          scheduling meetings with decision-makers in a variety of sectors.
        </p>
        <ul data-aos="zoom-in" data-aos-delay="100">
          <li>
            <i className="bx bx-check-double"></i>
            <b>Time and Cost Savings: </b> Scheduling appointments can be a
            time-consuming and expensive procedure, particularly for companies
            without a dedicated sales team. Businesses can free up time and
            resources to focus on other crucial duties by outsourcing
            appointment setting to us.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Increased Sales Opportunities: </b>By assisting companies with
            lead generation and prospect qualification, we can help them
            increase their sales chances. We can assist companies in getting
            their foot in the door and pitching their products or services
            directly to prospective customers by scheduling meetings with
            decision-makers.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Customized Strategy: </b>Recognizing that every company is
            different, we adapt our appointment-making strategy to fit the needs
            and objectives of each of our clients. We collaborate closely with
            companies to comprehend their target market and sales process in
            order to create a tailored appointment setup strategy.
          </li>
        </ul>

        <p data-aos={"fade-up"} data-aos-delay={"100"}>
          By using our appointment setting services can offer businesses a
          number of advantages that can help them to improve their sales
          process, expand their sales opportunities, and ultimately expand their
          company.
        </p>
      </div>
    </div>
  );
}
