import React from "react";
import KrillFooter from "../../../../footer/KrillFooter";
import KrillHeader from "../../../../header/KrillHeader";
import LeadGeneration from "./LeadGeneration";

export default function LeadGenerationPage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <LeadGeneration showDetails={true}></LeadGeneration>
      <KrillFooter></KrillFooter>
    </div>
  );
}
