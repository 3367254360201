import { React } from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import ButtonMailto from "../../common/ButtonMailto";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ValidationService from "./ContactService";

export default function Contact() {
  useEffect(() => {
    Aos.init();
  }, []);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [showThanks, setShowThanks] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setShowThanks(false);
    setField("name", "");
    setField("email", "");
    setField("contact", "");
    setField("budget", "");
    setField("details", "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      handlePostMessage();
    }
  };

  const findFormErrors = () => {
    const { name, email, contact, budget, details } = form;
    const newErrors = {};
    const emailRegex = /\S+@\S+\.\S+/;

    if (!name || name === "") newErrors.name = "Name field is required!";

    if (!email || email === "") {
      newErrors.email = "Email field is required!";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!contact || contact === "")
      newErrors.contact = "Contact field is required!";

    if (!budget || budget === "")
      newErrors.budget = "Budget field is required!";

    if (!details || details === "")
      newErrors.details = "Some details are required!";
    return newErrors;
  };

  const handlePostMessage = () => {
    const { name, email, contact, budget, details } = form;
    const dataObject = {
      name: name,
      email: email,
      contact: contact,
      budget: budget,
      details: details,
    };

    let postBody = JSON.stringify(dataObject);
    ValidationService.postMessage(postBody).then(function (response) {
      setShowThanks(true);

      if (response == "s") {
        setMessage(
          "Thank you for submitting the response! Our sales team will connect with you shortly."
        );
        setIsSuccess(true);
      } else {
        setMessage(
          "Something went wrong, please try submitting the response again!"
        );
        setIsSuccess(false);
      }

      setTimeout(() => {
        handleClose();
      }, 7000);
    });
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  return (
    <section id="contact" className="contact section-style-20">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Reach out to Us</h2>
          <p>
            Krill Technologies is a global firm with a presence in various
            geographical regions including India, Dubai (UAE), and the United
            States. We will be happy to help you in any possible way to
            accelerate your growth and fulfill your b2b needs.
          </p>
        </div>

        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.2700979600036!2d73.94251491414953!3d18.56185787275646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c19128defcaf%3A0x6c0ca98cbf85652b!2sKrill%20Technologies%20Private%20Limited.!5e0!3m2!1sen!2sin!4v1677185121772!5m2!1sen!2sin"
            height="270px"
            width="100%"
            frameBorder="0"
            allowFullScreen
          />
        </div>

        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="info" data-aos={"zoom-in"} data-aos-delay={"100"}>
              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <ButtonMailto
                  label="info@krilltech.com"
                  mailto="mailto:info@krilltech.com"
                ></ButtonMailto>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>
                  <a href="tel:number">+91 8793230954</a>
                </p>
                <p>
                  <a href="tel:number">+1-646-583-0169</a>
                </p>
              </div>

              <div className="phone">
                <i className="bi bi-linkedin"></i>

                <h4>Linkedin:</h4>
                <Link
                  className="linkedin"
                  target={"_blank"}
                  to="https://www.linkedin.com/company/krill-tech/"
                >
                  Krill Technologies
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">
            <Form>
              <div className="row gy-2 gx-md-3 section-style-40">
                <Form.Group
                  className="col-md-6 form-group"
                  controlId="formBasicName"
                >
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder="Name*"
                    onChange={(e) => setField("name", e.target.value)}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="col-md-6 form-group"
                  controlId="formBasicEmail"
                >
                  <Form.Control
                    type="email"
                    className="form-control "
                    placeholder="name@example.com*"
                    onChange={(e) => setField("email", e.target.value)}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="col-md-6 form-group aboutMargin"
                  controlId="formBasicContact"
                >
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder="Contact number with country code*"
                    onChange={(e) => setField("contact", e.target.value)}
                    isInvalid={!!errors.contact}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contact}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="col-md-6 form-group aboutMargin"
                  controlId="formBasicBudget"
                >
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder="Estimated Budget $(USD)*"
                    onChange={(e) => setField("budget", e.target.value)}
                    isInvalid={!!errors.budget}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.budget}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="col-12 form-group aboutMargin"
                  controlId="formBasicDescription"
                >
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder="Tell Us More:*"
                    onChange={(e) => setField("details", e.target.value)}
                    isInvalid={!!errors.details}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.details}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="text-center col-12 aboutMargin">
                  <Button variant="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                </div>

                <div id="header-model">
                  {showThanks && isSuccess && (
                    <p className="successMessage">{message}</p>
                  )}
                  {showThanks && !isSuccess && (
                    <p className="errorMessage">{message}</p>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}
