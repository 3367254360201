import "./App.css";
import { Routes, Route } from "react-router-dom";
import KrillApp from "./components/krillapp/KrillApp";
import AboutUsPage from "./components/content/aboutussection/AboutUsPage";
import KrillServicesPage from "./components/content/servicessection/servicespage/KrillServicesPage";
import LeadGenerationPage from "./components/content/servicessection/servicespage/LeadGenerationPage/LeadGenerationPage";
import BlogSectionPage from "./components/content/BlogSection/BlogSectionPage";
import AccountBasedMarketingPage from "./components/content/servicessection/servicespage/AccountBasedMarketingPage/AccountBasedMarketingPage";
import AudienceMarketResearchPage from "./components/content/servicessection/servicespage/AudienceMarketResearch/AudienceMarketResearchPage";
import ContentSyndicationPage from "./components/content/servicessection/servicespage/ContentSyndicationPage/ContentSyndicationPage";
import EmailMarketingPage from "./components/content/servicessection/servicespage/EmailMarketingPage/EmailMarketingPage";
import DataServicesPage from "./components/content/servicessection/servicespage/DataServicesPage/DataServicesPage";
import TermsOfUsePage from "./components/content/termsOfUse/TermsOfUsePage";
import GdprPage from "./components/content/gdpr/GdprPage";
import PrivacyPage from "./components/content/privacyPolicy/PrivacyPage";
import KrillHelmet from "./components/common/KrillHelmet";

function App() {
  return (
    <div className="App">
      <KrillHelmet />
      <Routes>
        <Route path="/" element={<KrillApp />}></Route>
        <Route path="about" element={<AboutUsPage />}></Route>
        <Route path="services" element={<KrillServicesPage />}></Route>
        <Route
          path="services/lead-generation"
          element={<LeadGenerationPage />}
        ></Route>
        <Route
          path="services/email-marketing"
          element={<EmailMarketingPage />}
        ></Route>
        <Route
          path="services/account-based-marketing"
          element={<AccountBasedMarketingPage />}
        ></Route>
        <Route
          path="services/data-services"
          element={<DataServicesPage />}
        ></Route>
        <Route
          path="services/audience-market-research"
          element={<AudienceMarketResearchPage />}
        ></Route>
        <Route
          path="services/content-syndication"
          element={<ContentSyndicationPage />}
        ></Route>
        <Route path="terms" element={<TermsOfUsePage />}></Route>
        <Route path="gdpr" element={<GdprPage />}></Route>
        <Route path="blog" element={<BlogSectionPage />}></Route>
        <Route path="privacy" element={<PrivacyPage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
