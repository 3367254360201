import React from "react";
import KrillFooter from "../../footer/KrillFooter";
import KrillHeader from "../../header/KrillHeader";
import Gdpr from "./Gdpr";

export default function GdprPage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <Gdpr></Gdpr>
      <KrillFooter></KrillFooter>
    </div>
  );
}
