import React, { useEffect } from "react";
import PureCounter from "@srexi/purecounterjs";

function KrillCount() {
  useEffect(() => {
    const pure = new PureCounter({
      selector: ".purecounter",
      start: 0,
      end: 100,
      duration: 2,
      delay: 10,
      once: true,
      repeat: false,
      decimals: 0,
      legacy: true,
      filesizing: false,
      currency: false,
      separator: false,
    });
  }, []);

  return (
    <div id="counts" className="counts section-bg">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-3 col-md-5 col-12 d-md-flex align-items-md-stretch">
            <div className="count-box">
              <span
                data-purecounter-start={"0"}
                data-purecounter-end={"200"}
                data-purecounter-duration={"2"}
                className="purecounter"
              ></span>
              <span>+</span>
              <p>Happy Customers</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-5 col-12 d-md-flex align-items-md-stretch">
            <div className="count-box">
              <span
                data-purecounter-start={"0"}
                data-purecounter-end={"1000"}
                data-purecounter-duration={"2"}
                className="purecounter"
              ></span>
              <span>+</span>
              <p>Campaigns</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-5 col-12 d-md-flex align-items-md-stretch">
            <div className="count-box">
              <span
                data-purecounter-start={"0"}
                data-purecounter-end={"380000"}
                data-purecounter-duration={"2"}
                className="purecounter"
              ></span>
              <span>+</span>
              <p>Leads Generated</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-5 col-12 d-md-flex align-items-md-stretch">
            <div className="count-box">
              <span
                data-purecounter-start={"0"}
                data-purecounter-end={"70500000"}
                data-purecounter-duration={"2"}
                className="purecounter"
              ></span>
              <span>+</span>
              <p>Contacts Validated</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KrillCount;
