import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import ContentSyndicationDetails from "./ContentSyndicationDetails";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function ContentSyndication({ showDetails }) {
  const [show, setShow] = useState(showDetails);

  useEffect(() => {
    Aos.init();
  }, []);
  const video = require("../../../../../assets/images/services/Lead_Generation_2.png");
  return (
    <section
      id="content-syndication"
      className="about-video section-style-40 content-syndication-bg"
    >
      <HelmetProvider>
        <Helmet>
          <title>Content Syndication</title>
          <meta
            name="description"
            content="We can help you to spread or share web material, such as articles, blog posts, videos, or other media types, across other websites or platforms."
          />
          <meta
            name="keywords"
            content="Content distribution, Content marketing, Content amplification, Content promotion, Syndicated content, Digital distribution, Online distribution, Marketing syndication, Content sharing, Multi-channel distribution, Single Touch Email, Double Touch Email Marketing, Inbound Traffic, Paid SEO"
          />
        </Helmet>
      </HelmetProvider>
      <div className="mobile-margin visible-xs"></div>

      {show && <div className="services-details-top-padding"></div>}
      <div className="container" data-aos={"fade-up"}>
        <div className="row content">
          <h1>Content Syndication</h1>
          {show && (
            <p className="fst-italic">
              At Krill Technologies, we have a process of spreading or sharing
              web material, such as articles, blog posts, videos, or other types
              of media, across other websites or platforms. Your brand will rise
              as a result of content syndication to other websites, which will
              allow you to reach a larger audience.
            </p>
          )}
          {show && <p>Benefits to collaborate with us:</p>}

          {show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-right"}
              data-aos-delay={"100"}
            >
              <ul>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Increased SEO:</b> By gaining more traffic and backlinks to
                  your website, content syndication can raise your website's
                  search engine rankings
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Cost-effective Marketing:</b> Content syndication can
                  effectively promote your business without incurring
                  significant advertising costs
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Enhanced lead generation: </b> You can create more leads
                  and even boost sales by reaching a larger audience
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Enhanced thought leadership: </b> Publishing top-notch
                  content on a regular basis will help you establish your
                  business as a thinking leader in your sector
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b> Expertise:</b> As our staff has a wealth of knowledge in
                  lead generation and content syndication, we can assist you in
                  producing high-quality material that appeals to your target
                  demographic
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b> Customization:</b> To ensure you obtain the greatest
                  outcomes possible, we may adjust our content syndication
                  services to match your unique demands and goals
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b> Analytics: </b>We offer thorough analytics and reporting
                  so you may monitor the success of your syndicated material and
                  modify your approach as necessary
                </li>

                <li>
                  <i className="bx bx-check-double"></i>
                  <b> Scalability:</b> We are able to scale our content
                  syndication services as your company expands, ensuring that
                  you always have access to the resources you require to be
                  successful
                </li>
              </ul>
            </div>
          )}

          {!show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-right"}
              data-aos-delay={"100"}
            >
              <p>
                As a B2B business, we recognize the significance of successfully
                connecting with and engaging with our target audience. By
                distributing your content across a network of dependable
                publishers and channels, our content syndication service is made
                to assist you in accomplishing that. With the help of our
                content syndication service, you can broaden your audience,
                raise brand recognition, produce top-notch leads, and boost
                sales.
              </p>
              <p>
                We take a data-driven, results-oriented strategy to content
                syndication with the goal of generating quantifiable returns for
                our clients. The performance of your content is tracked and
                analyzed using the most recent technologies and tools, allowing
                us to make data-driven choices that optimize your campaigns and
                produce the best outcomes. We can assist you in achieving your
                marketing objectives through content syndication.
              </p>
              <Link
                className="btn-learn-more"
                to={`/services/content-syndication`}
              >
                <span>Know More</span>
              </Link>
            </div>
          )}

          <div
            className="col-lg-4 video-box align-self-baseline"
            data-aos={"fade-left"}
            data-aos-delay={"100"}
          >
            <img src={video} className="img-fluid" alt="Content Syndication" />
          </div>
          {show && <ContentSyndicationDetails />}
        </div>
      </div>
    </section>
  );
}
