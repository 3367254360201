import React from "react";
import KrillFooter from "../../../../footer/KrillFooter";
import KrillHeader from "../../../../header/KrillHeader";
import EmailMarketing from "./EmailMarketing";

export default function EmailMarketingPage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <EmailMarketing showDetails={true}></EmailMarketing>
      <KrillFooter></KrillFooter>
    </div>
  );
}
