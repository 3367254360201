import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonMailto from "../common/ButtonMailto";
import Form from "react-bootstrap/Form";
import ValidationService from "../content/contactsection/ContactService";

function KrillHeader() {
  const krilllogo = require("../../../src/assets/images/logo/Krill.png");

  const [open, setopen] = useState(false);
  const [show, setShow] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const handleShow = () => setShow(true);
  const [message, setMessage] = useState("");
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOnClick = (e) => {
    e.preventDefault();
    setopen(!open);
  };

  const handleClose = () => {
    setShow(false);
    setShowThanks(false);
    setField("name", "");
    setField("email", "");
    setField("contact", "");
    setField("budget", "");
    setField("details", "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      handlePostMessage();
    }
  };

  const findFormErrors = () => {
    const { name, email, contact, budget, details } = form;
    const newErrors = {};
    const emailRegex = /\S+@\S+\.\S+/;

    if (!name || name === "") newErrors.name = "Name field is required!";

    if (!email || email === "") {
      newErrors.email = "Email field is required!";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!contact || contact === "")
      newErrors.contact = "Contact field is required!";

    if (!budget || budget === "")
      newErrors.budget = "Budget field is required!";

    if (!details || details === "")
      newErrors.details = "Some details are required!";
    return newErrors;
  };

  const handlePostMessage = () => {
    const { name, email, contact, budget, details } = form;
    const dataObject = {
      name: name,
      email: email,
      contact: contact,
      budget: budget,
      details: details,
    };

    let postBody = JSON.stringify(dataObject);
    ValidationService.postMessage(postBody).then(function (response) {
      setShowThanks(true);

      if (response == "s") {
        setMessage(
          "Thank you for submitting the response! Our sales team will connect with you shortly. This window will be closed automatically in next 5 seconds"
        );
        setIsSuccess(true);
      } else {
        setMessage(
          "Something went wrong, please try submitting the response again!"
        );
        setIsSuccess(false);
      }

      setTimeout(() => {
        handleClose();
      }, 7000);
    });
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  return (
    <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo">
            <Link className="scrollto" to={`/`}>
              <img src={krilllogo} alt="" className="img-fluid logotitle" />
            </Link>
          </h1>

          <nav
            id="navbar"
            className={open ? "navbar-mobile bi-list bi-x" : "navbar"}
          >
            <ul>
              <li>
                <Link className="nav-link scrollto active" to={`/`}>
                  Home
                </Link>
              </li>

              <li className="dropdown">
                <Link className="scrollto" to={`/services`}>
                  <span>Services</span> <i className="bi bi-chevron-right"></i>
                </Link>

                <ul>
                  <li className="dropdown">
                    <Link
                      className="nav-link scrollto"
                      to={`/services/lead-generation`}
                    >
                      <span>Lead Generation</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </Link>
                    <ul>
                      {/* <li>
                      <Link
                        className="nav-link scrollto"
                        to={`/services/lead-generation#mql`}
                      >
                        MQL
                      </Link>
                    </li> */}
                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/lead-generation`}
                        >
                          HQL - High-quality Lead Generation
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/lead-generation`}
                        >
                          SQL - Sales Qualified Leads
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/content-syndication`}
                        >
                          Tele-Marketing
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/lead-generation`}
                        >
                          Appointment Setting
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className="nav-link scrollto"
                      to={`/services/account-based-marketing`}
                    >
                      <span>Account Based Marketing</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </Link>
                  </li>
                  <li className="dropdown">
                    <Link
                      className="nav-link scrollto"
                      to={`/services/content-syndication`}
                    >
                      <span>Content Syndication</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </Link>
                    <ul>
                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/content-syndication`}
                        >
                          One Touch Emails
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/content-syndication`}
                        >
                          Double Touch Email Marketing Campaign
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/content-syndication`}
                        >
                          Inbound Traffic with paid Ads and SEO Services
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className="nav-link scrollto"
                      to={`/services/email-marketing`}
                    >
                      <span>Email Marketing</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link scrollto"
                      to={`/services/audience-market-research`}
                    >
                      <span>Audience & Market Research</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </Link>
                  </li>
                  <li className="dropdown">
                    <Link
                      className="nav-link scrollto"
                      to={`/services/data-services`}
                    >
                      <span>Data Services</span>{" "}
                      <i className="bi bi-chevron-right"></i>
                    </Link>

                    <ul>
                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/data-services`}
                        >
                          Email Verification
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/data-services`}
                        >
                          Data Cleansing
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link scrollto"
                          to={`/services/data-services`}
                        >
                          Propriety and third party publishing sites
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/* <li>
              <Link className="nav-link scrollto" to={`/blog`}>
                <span>Blog</span> <i className="bi bi-chevron-right"></i>
              </Link>
            </li> */}
              <li>
                <Link className="nav-link scrollto" to={`/about`}>
                  About
                </Link>
              </li>
              <li>
                <a className="getstarted scrollto" onClick={handleShow}>
                  Contact Us
                </a>
              </li>
            </ul>
            <i
              className="bi bi-list mobile-nav-toggle"
              onClick={handleOnClick}
            ></i>
          </nav>
        </div>
      </header>

      <Form>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header closeButton>
            <Modal.Title>Krill Technologies</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please fill out the following details for us to understand your
              requirement and serve you better. We would love to assist you!
            </p>
            <Form.Group
              className="form-group aboutMargin"
              controlId="formBasicName"
            >
              <Form.Control
                className="form-control"
                type="text"
                placeholder="Name*"
                // onChange={(e) => setName(e.target.value)}
                onChange={(e) => setField("name", e.target.value)}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="form-group aboutMargin"
              controlId="formBasicEmail"
            >
              <Form.Control
                type="email"
                className="form-control"
                placeholder="name@example.com*"
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                onChange={(e) => setField("email", e.target.value)}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="form-group aboutMargin"
              controlId="formBasicContact"
            >
              <Form.Control
                className="form-control"
                type="text"
                placeholder="Contact number with country code*"
                // onChange={(e) => setContact(e.target.value)}
                onChange={(e) => setField("contact", e.target.value)}
                isInvalid={!!errors.contact}
              />
              <Form.Control.Feedback type="invalid">
                {errors.contact}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="form-group aboutMargin"
              controlId="formBasicBudget"
            >
              <Form.Control
                className="form-control"
                type="text"
                placeholder="Estimated Budget $(USD)*"
                onChange={(e) => setField("budget", e.target.value)}
                isInvalid={!!errors.budget}
              />
              <Form.Control.Feedback type="invalid">
                {errors.budget}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="form-group aboutMargin"
              controlId="formBasicDescription"
            >
              <Form.Control
                className="form-control"
                type="text"
                placeholder="Tell Us More:*"
                onChange={(e) => setField("details", e.target.value)}
                isInvalid={!!errors.details}
              />
              <Form.Control.Feedback type="invalid">
                {errors.details}
              </Form.Control.Feedback>
            </Form.Group>
            <p className="aboutMargin">
              Alternatively, you can also drop us an email on
              <ButtonMailto
                label="info@krilltech.com"
                mailto="mailto:info@krilltech.com"
              ></ButtonMailto>{" "}
              and our sales team would be happy to connect with you via email
            </p>
            <div id="header-model">
              {showThanks && isSuccess && (
                <p className="successMessage">{message}</p>
              )}
              {showThanks && !isSuccess && (
                <p className="errorMessage">{message}</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default KrillHeader;
