import React from "react";
import TermsOfUse from "./TermsOfUse";
import KrillHeader from "../../header/KrillHeader";
import KrillFooter from "../../footer/KrillFooter";

export default function TermsOfUsePage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <TermsOfUse></TermsOfUse>
      <KrillFooter></KrillFooter>
    </div>
  );
}
