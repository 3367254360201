import React from "react";
import { useEffect } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

function HomeSection() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section id="hero" className="section-style d-flex align-items-center">
      <HelmetProvider>
        <Helmet>
          <title>High Quality B2B Lead Generation and Data Services</title>
          <meta
            name="description"
            content="We help your sales pipeline with high quality & dependable B2B
          services"
          />
          <meta
            name="keywords"
            content="Lead Generation, Sales qualified Lead, Marketing Qualified Lead, High quality lead, Demand generation, Email marketing, Email marketing services, Sales pipeline, Inbound marketing, Sales funnel, Appointment setting services, Appointment scheduling, Data services, Data cleansing, Email campaigns, Content marketing, Tele Marketing, Digital Marketing"
          />
        </Helmet>
      </HelmetProvider>
      <div
        className="container position-relative"
        data-aos={"fade-up"}
        data-aos-delay={"100"}
      >
        <div className="row justify-content-left">
          <div className="col-xl-7 col-lg-9 text-center">
            <h1>High Quality B2B Lead Generation and Data Services</h1>
            <h2>
              We help your sales pipeline with high quality & dependable B2B
              services{" "}
            </h2>
          </div>
        </div>
        <div className="text-center">
          <a href="/Services" className="btn-get-started scrollto">
            Get Started
          </a>
        </div>

        {/* <div id="row-box" className="row icon-boxes">
          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            data-aos={"zoom-in"}
            data-aos-delay={"200"}
          >
            <div className="icon-box">
              <div className="icon">
                <i className="ri-stack-line"></i>
              </div>

              <h4 className="title">
                <Link to={`/services/content-syndication`}>
                  <span>Content Syndication</span>{" "}
                </Link>
              </h4>
              <p className="description">
                Let your content be heard, read and talked about. Content
                Marketing is still a powerful tool despite the rise of new
                contenders.{" "}
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            data-aos={"zoom-in"}
            data-aos-delay={"300"}
          >
            <div className="icon-box">
              <div className="icon">
                <i className="ri-palette-line"></i>
              </div>
              <h4 className="title">
                <Link to={`/services/lead-generation`}>
                  <span>Lead & Demand Generation</span>{" "}
                </Link>
              </h4>
              <p className="description">
                We are a trusted partner to technology companies in their
                never-ending search for fresh leads in various markets across
                different business verticals.{" "}
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            data-aos={"zoom-in"}
            data-aos-delay={"400"}
          >
            <div className="icon-box">
              <div className="icon">
                <i className="ri-command-line"></i>
              </div>
              <h4 className="title">
                <Link to={`/services/account-based-marketing`}>
                  <span>Account Based Marketing</span>{" "}
                </Link>
              </h4>
              <p className="description">
                Our account-based marketing (ABM) approach includes primary
                research with recognition, observation, and engaging methods to
                gain insights into your target accounts
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            data-aos={"zoom-in"}
            data-aos-delay={"500"}
          >
            <div className="icon-box">
              <div className="icon">
                <i className="ri-fingerprint-line"></i>
              </div>
              <h4 className="title">
                <Link to={`/services/data-services`}>
                  <span>Data Services</span>{" "}
                </Link>
              </h4>
              <p className="description">
                We offers a wholesome solution and campaign execution through
                our direct marketing channels which begins with providing the
                right data
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default HomeSection;
