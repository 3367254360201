const ValidationService = {
  postMessage: function (postBody) {
    console.log("Request: " + postBody);

    const requestOptions = {
      method: "POST",
      headers: { "Access-Control-Allow-Origin": "*" },
      body: postBody,
      mode: "no-cors",
    };

    return fetch(
      "https://td08xfiprj.execute-api.ap-south-1.amazonaws.com/prod/KrillServerlessWebsite",
      requestOptions
    )
      .then(function (response) {
        console.log("Response: " + response);
        if (response.status === 0) {
          console.log("Successful");
          return "s";
        } else {
          return "e";
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  },
};

export default ValidationService;
