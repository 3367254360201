import React from "react";

export default function Gdpr() {
  return (
    <section id="about">
      <div className="container">
        <header className="section-header-gdpr section-style-20 aboutMargin">
          <div className="section-style-40"></div>
          <b>General Data Protection Regulation</b>
          <div className="section-style-20"></div>
          <p>
            <b>1. Introduction: </b>
          </p>
          <p>
            To run our business operations Krill needs to collect, store and
            process data belonging to a diverse variety of identifiable
            individuals. These can include: Current, past and prospective
            employees; customers, prospects and business contacts; contractors
            and suppliers; users and subscribers to websites; visitors; personal
            data belonging to Krill clients (third party data) and other
            individuals with whom the organization has a relationship.
          </p>
          <p>
            This policy describes how these personal data records are collected,
            stored and handled to meet the company’s data protection standards
            and comply with relevant legislation relating to data privacy,
            including the GDPR. Breaches of confidentiality and failure to
            respect the rights of individuals, can lead to fines, legal actions
            and reputational damage.
          </p>
          <b>2. Scope: </b>
          <p>
            The policy applies to all people, processes and systems in all group
            organizations that have access to, or use, personal data pertaining
            to citizens. These include employees, contractors, directors, board
            members and any third parties who have access to the data for lawful
            reasons.
          </p>
          <p>
            <b>3. Data Privacy </b>
          </p>
          <p>
            <b>3.1 GDPR Principles :</b>
          </p>
          <p>
            The EU GDPR describes how organizations must collect, store and
            handle personal data. There are a number of fundamental principles
            upon which the GDPR is based. These state that personal data should
            be:
          </p>
          <p>
            • Collected and processed legally, fairly and the individual should
            be aware what data is collected, for how long and for what purpose;
          </p>
          <p>
            • Collected only for the purpose specified and agreed and not used
            for any other purpose, with some exceptions, for example public
            interest;
          </p>
          <p>
            • “Minimized”, i.e. no more data should be collected than is
            required to fulfill the purpose for which they were obtained;
          </p>
          <p>• Accurate and kept up to date, completed and corrected;</p>
          <p>
            • Not retained for longer than they are required, with some
            exceptions in public interest;
          </p>
          <p>
            • protected, through the appropriate systems and procedures, against
            unauthorized or unlawful processing or accidental loss,
            modification, destruction or damage;
          </p>
          <p>
            • Not transferred to a country outside the EU-approved list of
            nations, unless the appropriate security and contractual clauses are
            in place. The organization is responsible for, and must be able to
            demonstrate compliance with, the above requirements. This can be in
            relation to current and future, planned, processing activities or
            systems.
          </p>
          <p>
            <b>3.2 Lawful Basis / Consent:</b>
          </p>
          <p>
            Unless there is a valid contractual or legal reason (lawful basis)
            for collecting and processing a person’s data, it is often necessary
            to obtain their explicit consent. In the case of children (under 13
            years old in the UK and between 13 and 16 in other EU countries),
            the consent of a parent or legal guardian must be obtained for their
            data to be collected and processed. Special category or sensitive
            data needs particularly careful consideration and the processing of
            this data is prohibited, unless there is a lawful basis for
            collecting and processing them. People must be clearly told how
            their data will be used, on what basis and for how long, at the time
            it is collected. A clear explanation must also be given of their
            rights in relation to the data, including the right to withdraw
            consent. In cases where the data is obtained from a third party,
            this information should be provided within a month of the data being
            acquired by the organization.
          </p>
          <p>
            <b> 3.3 Complying with the GDPR: </b>
          </p>
          <p>
            Krill Technologies is committed to complying with the GDPR and other
            relevant legislation in relation to the privacy of individuals whose
            data the organization collects, holds and processes. The following
            measures are taken to support the principles and rights specified by
            the legislation:
          </p>
          <p>
            • All staff and third parties involved in handling personal data
            understand their responsibilities in terms of data protection and
            privacy.
          </p>
          <p>
            • Training in data security and privacy has been provided to all
            staff.
          </p>
          <p>
            • Policies and codes of conduct are in place to ensure data
            protection and privacy.
          </p>
          <p>
            • There are regular reviews of policies and procedures around
            personal data.
          </p>
          <p>
            • Privacy by design and by default, including Data Protection Impact
            Assessments, is adopted for all new or changed systems and processes
            impacting personal data.
          </p>
          <p>
            • Data is disposed of as required by the data retention policies and
            the GDPR.
          </p>
          <p>
            • Controls are in place around transfers of personal data to non-EU
            approved countries.
          </p>
          <p>
            {" "}
            • Contractual agreements are in place with third party Processors,
            Joint Controllers and cloud service providers; they adequately
            handle personal data privacy and security; and the appropriate data
            transfer security measures are in place.
          </p>
          <p>
            • All processing activities and transfers involving personal data
            records have been identified by the organization and are covered by
            the measures above and respect the requirements of the GDPR.
          </p>
          <p>
            • The risks for each type of personal data have been assessed and
            controls are in place to mitigate risks.
          </p>
          <p>
            • The lawful basis for processing personal data has been defined and
            all processing is lawful and justified.
          </p>
          <p>
            • Valid Privacy Notices are in place for all individuals whose
            personal data is collected, held and processed.
          </p>
          <p>
            • Special category data is identified and handled in the appropriate
            manner, given its sensitivity. • Rules regarding consent are
            followed.
          </p>
          <p>
            • Processes are in place to enable individual citizens to exercise
            their rights in relation to their personal data and these requests
            are handled within the required timeframes.
          </p>
          <p>
            • The organization has documented all personal data processing
            activities and can demonstrate the appropriate data protection and
            privacy measures are in place, as well as compliance with the GDPR.
          </p>
          <p>
            • These measures and controls are regularly reviewed, as part of the
            management review of data privacy and protection.
          </p>
          <p>
            <b>4. Data Management: </b>
          </p>
          <p>
            In order to protect personal data when they are being used or
            processed, the organization has the following policy:
          </p>

          <p>
            • When working with personal data, employees must ensure that
            screens are locked when not in use or left unattended. Employees
            should avoid having their screens overlooked when working with
            personal data.
          </p>
          <p>
            {" "}
            • Induction training, and regular follow-up training, for all
            employees on data security, data management and physical security.
          </p>
          <p> • A clean desk policy is in place.</p>
          <p>
            • Personal data should not be shared informally. In particular, it
            should not be sent by email without secure encryption.
          </p>
          <p>
            • Data must be encrypted before being transferred electronically.
          </p>
          <p>
            • Employees should not save copies of personal data to their own
            computers. Data should be accessed and updated on a centrally and
            securely held version.
          </p>
          <p>
            • Data must be held in as few places as necessary and the creation
            of unnecessary data sets avoided.
          </p>
          <p>• Data should be updated as inaccuracies are discovered.</p>
          <p>
            • Marketing databases should be checked against industry suppression
            files on a regular basis.
          </p>
          <p>
            <b>5. Cloud Security: </b>
          </p>
          <p>
            Where data are stored in, or transferred to, a cloud environment,
            the organization collaborates with the cloud service provider to
            satisfy itself that the security measures employed by the cloud
            provider are adequate and appropriate. The organization carries out
            regular data audits to ascertain which personal data are stored in
            the cloud.
          </p>
          <p>
            <b>6. IT Security: </b>
          </p>
          <p>
            <b>• Access Control </b>– access is only given to users who are
            authorized to use systems; and the user only has permission to use
            functionality appropriate to their current role. Every user must use
            their own unique user ID and a strong password and must not share
            log-in credentials. Passwords are changed every 3 months. When
            personnel leave the organization, access and passwords are cancelled
            immediately. The same process is followed in the case of long-term
            absence.{" "}
          </p>
          <p>
            {" "}
            <b>• Malware Protection </b>– the organization has installed
            anti-virus and anti-malware software that constantly scans the
            network to detect and prevent threats. Alerts are dealt with
            promptly following detection. This software is reviewed for the
            latest updates and patches, which are deployed in a controlled way
            as quickly as possible after release, to ensure any vulnerabilities
            are removed. Staff are trained to, as far as possible, recognize
            phishing emails and websites and to exercise extreme caution when
            opening emails and using the internet.{" "}
          </p>
          <p>
            • No personal data is stored on individual PCs and Laptop computers.
            Data may be uploaded to approved cloud service providers and
            transferred to clients using secure FTP sites or unique password
            encryption.{" "}
          </p>
          <p>
            <b>• Secure Configuration </b> of hardware and software. Unused
            software and services, especially old versions of widely used
            software, are removed from devices to reduce the number of potential
            threats. Regular checks are made on what software or services are
            running on the network and any unnecessary or unknown software is
            reviewed for removal. Regular vulnerability scans and penetration
            tests are run to test the network and systems for weaknesses.
            Vulnerabilities are addressed promptly. Default passwords for
            hardware and software are changed.
          </p>
        </header>
      </div>
    </section>
  );
}
