import React, { Component } from "react";
import { Link } from "react-router-dom";
import Text from "react-text";

export class KrillFooter extends Component {
  render() {
    return (
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 footer-contact">
                <h4>India Office</h4>
                <h6>Krill Technologies Pvt. Ltd.</h6>
                <Text>
                  {`Office No. 17 & 18, 6th Floor,\n
                    Downtown City Vista, Kharadi, \n
                    Pune - 411014, INDIA \n`}
                </Text>
                <p>+918793230954 {"\n"}</p>
                <h4 className="addressMargin">Dubai Office</h4>
                <h6>Krill Global Services</h6>
                <Text>
                  {`IFZA Property FZCO, Dubai Silicon Oasis, \n
                  DDP, Building A2 \n
                  Dubai, United Arab Emirates \n`}
                </Text>
                <p>+97142285285 {"\n"}</p>
                <h4 className="addressMargin">US Office</h4>
                <h6>Krill Technologies</h6>
                <Text>
                  {`77 Water St, Brooklyn, \n
                  New York, NY 11201, \n
                  United States \n`}
                </Text>
                <p>+16465830169 {"\n"}</p>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <Link className="scrollto" to={`/`}>
                      <span>Home</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link className="scrollto" to={`/services`}>
                      <span>Services</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link className="scrollto" to={`/gdpr`}>
                      <span>GDPR</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link className="scrollto" to={`/terms`}>
                      <span>Terms of Use</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link className="scrollto" to={`/privacy`}>
                      <span>Privacy policy</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link className="nav-link scrollto" to={`/about`}>
                      About Us
                    </Link>
                  </li>

                  {/* <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link className="scrollto" to={`/blog`}>
                      <span>Blog</span>
                    </Link>
                  </li> */}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link className="scrollto" to={`/services/lead-generation`}>
                      <span>Lead Generation</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      className="scrollto"
                      to={`/services/account-based-marketing`}
                    >
                      <span>Account Based Marketing</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      className="scrollto"
                      to={`/services/content-syndication`}
                    >
                      <span>Content Syndication</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link className="scrollto" to={`/services/email-marketing`}>
                      <span>Email Marketing</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link
                      className="scrollto"
                      to={`/services/audience-market-research`}
                    >
                      <span>Audience & Market Research</span>
                    </Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link className="scrollto" to={`/services/data-services`}>
                      <span>Data Services</span>
                    </Link>
                  </li>
                </ul>
              </div>

              {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Do you want to know more?</h4>
                <p>
                  Please leave your email id here and our sales team will
                  contact you via email
                </p>
                <form action="" method="post">
                  <input type="email" name="email" />
                  <input type="submit" value="Submit" />
                </form>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            &copy; Copyright <strong>Krill Technologies Pvt. Ltd.</strong> All
            Rights Reserved
          </div>
        </div>
      </footer>
    );
  }
}

export default KrillFooter;
