import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function AudienceMarketResearch({ showDetails }) {
  const [show, setShow] = useState(showDetails);
  useEffect(() => {
    Aos.init();
  }, []);
  const video = require("../../../../../assets/images/services/Market_Research.png");
  return (
    <section
      id="audience-market-research"
      className="about-video section-style-40 audience-market-bg"
    >
      <HelmetProvider>
        <Helmet>
          <title>Audience Market Research</title>
          <meta
            name="description"
            content="Audience and Market research is an essential tool for businesses looking to comprehend their target audience and maintain their competitiveness"
          />
          <meta
            name="keywords"
            content="Audience research, Market research, Consumer research, Audience segmentation, Market segmentation, Market analysis, Industry research, Competitor research, Demographic research, Psychographic research, Customer insights, Market trends, Market intelligence, Customer behavior, Consumer behavior, Survey research, Focus group research, Data-driven insights, Buyer persona, Customer profiling"
          />
        </Helmet>
      </HelmetProvider>
      <div className="mobile-margin visible-xs"></div>

      {show && <div className="services-details-top-padding"></div>}
      <div className="container" data-aos={"fade-up"}>
        <div className="row  content">
          <h1>Audience Market Research</h1>
          {show && (
            <p className="fst-italic">
              Audience and Market research is an essential tool for businesses
              looking to comprehend their target audience and maintain their
              competitiveness. By understanding consumer behavior and
              preferences, businesses may make informed decisions about their
              marketing strategies, product development, and overall business
              operations. We offer audience and market research services that
              will have the following possible advantages:
            </p>
          )}

          {show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-right"}
              data-aos-delay={"100"}
            >
              <ul data-aos="zoom-in" data-aos-delay="200">
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>
                    Gain a deeper understanding of your target audience:
                  </b>{" "}
                  Audience and Market Research give firms information about the
                  tastes, wants, and behaviors of their target market. To better
                  fulfill the demands of customers, this information can be used
                  to customize marketing messages, product offerings, and
                  customer support
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Stay ahead of the competition: </b>Your business can stay
                  current with the newest trends and market changes by
                  performing regular audience and market research. Using this
                  knowledge can help you keep one step ahead of the competition
                  and establish your company as a market leader
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Identify new market opportunities:</b> Your business can
                  find new market opportunities and prospective development
                  areas by examining customer behavior and market data. This
                  might aid in market expansion and revenue growth for your
                  business
                </li>
              </ul>
            </div>
          )}

          {!show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-right"}
              data-aos-delay={"100"}
            >
              <p>
                We offer the professional audience and market research services
                to businesses so they can better comprehend their target market
                and develop winning growth strategies. Our research is
                customized to meet the specific requirements of each client, and
                our team of knowledgeable analysts uses state-of-the-art tools
                and methods to deliver insightful data that can be used right
                away in business operations. In addition to prospect research,
                we also provide market sizing and segmentation, brand analysis,
                competitive analysis, and other research services.
              </p>
              <p>
                Our data-driven methodology guarantees that all study is
                accurate, trustworthy, and usable. We have the knowledge and
                tools to support you in achieving your objectives, whether you
                want to introduce a new product, grow your company, or enhance
                your marketing initiatives. We take pleasure in providing
                businesses of all sizes with high-quality research services that
                are also reasonably priced and easily accessible. We can give
                you a competitive advantage in your sector.
              </p>
              <Link
                className="btn-learn-more"
                to={`/services/audience-market-research`}
              >
                <span>Know More</span>
              </Link>
            </div>
          )}
          <div
            className="col-lg-4 video-box align-self-baseline"
            data-aos={"fade-left"}
            data-aos-delay={"100"}
          >
            <img
              src={video}
              className="img-fluid"
              alt="Audience & Market Research"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
