import React from "react";
import { useEffect } from "react";
import Aos from "aos";

export default function DataServicesDetails() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <div
        id="email-verification"
        className="aboutMargin"
        data-aos={"fade-up"}
        data-aos-delay={"100"}
      >
        <p data-aos={"fade-up"} data-aos-delay={"10"}>
          Partnering with us for our Data Services can benefit companies by
          enhancing their operations, boosting their efficiency, and gaining a
          competitive edge.
        </p>
        <h3>Email Verification</h3>
        <p>
          The purpose of email verification services is to assist businesses in
          ensuring the accuracy and deliverability of their email addresses.
          Email addresses are verified using algorithms and manual reviews, and
          any that are false, inactive, or invalid are removed from the list. We
          as an Email verification service can assist businesses in increasing
          their email deliverability rates by eliminating invalid email
          addresses. As a result, more of their emails will be delivered to the
          intended receivers and fewer will be returned as spam. The following
          are some advantages of using our email authentication service:
        </p>
        <ul data-aos="zoom-in" data-aos-delay="100">
          <li>
            <i className="bx bx-check-double"></i>
            <b>Cost savings:</b> By lowering the volume of emails sent to
            invalid or dormant email addresses, we can help businesses save
            money. As a result, their email marketing expenses will go down, and
            their ROI will increase.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Improved data quality:</b> By eliminating incorrect or
            out-of-date email addresses, we can also assist businesses in
            enhancing the quality of their data. They can use this to enhance
            their overall marketing strategy and make better-informed business
            choices.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Compliance:</b> By ensuring that emails are only sent to
            recipients who have given their permission, we can assist businesses
            in adhering to laws like the GDPR and CAN-SPAM.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Time savings:</b> By automating the email verification procedure,
            we can help businesses save time. This increases their general
            effectiveness and enables them to concentrate on other crucial
            duties.
          </li>
        </ul>
        <p data-aos={"fade-up"} data-aos-delay={"100"}>
          By guaranteeing the accuracy and deliverability of their email lists,
          we can aid businesses in improving their email marketing campaigns.
          Utilizing our email verification service can result in increased ROI,
          better data quality, regulatory compliance, and resource savings.
        </p>
      </div>

      <div
        id="data-cleansing"
        className="aboutMargin"
        data-aos={"fade-up"}
        data-aos-delay={"100"}
      >
        <h3>Data Cleansing</h3>
        <p>
          As a supplier of data cleansing services, it is our mission to assist
          other businesses in cleaning, standardizing, and enhancing their data
          in order to increase its precision, thoroughness, and consistency. The
          following are some advantages of collaborating with us:
        </p>
        <ul data-aos="zoom-in" data-aos-delay="100">
          <li>
            <i className="bx bx-check-double"></i>
            <b>Better data quality: </b> By removing errors, inconsistencies,
            and duplicates from data, data cleansing serves to improve data
            quality. For making wise business choices, enhancing the customer
            experience, and increasing operational effectiveness, clean data is
            crucial.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Savings in time and money: </b> Cleaning data is a labor- and
            resource-intensive procedure. Companies can save time and money by
            outsourcing data cleansing to us instead of hiring and training
            internal employees, buying software, and managing data cleaning
            procedures.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Compliance and Regulation: </b> Strict rules regarding data
            security and privacy apply to many sectors. Heavy penalties and
            legal repercussions may follow noncompliance with these rules. By
            removing sensitive data, like personal information, from datasets,
            our data cleansing services make sure that businesses adhere to
            legal requirements.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Competitive advantage: </b> Businesses can concentrate on their
            core skills and gain a competitive edge by outsourcing data
            cleansing to us. Companies can improve their product and service
            offerings, better understand their consumers, and improve the
            overall customer experience with the assistance of clean data.
          </li>
        </ul>
        <p data-aos={"fade-up"} data-aos-delay={"100"}>
          In making better decisions for accurate research and reporting, clean
          data is a necessity. Our data cleansing services enable businesses to
          make informed business choices, spot trends, and project future
          performance using accurate and trustworthy data. In conclusion, our
          data cleansing services assist businesses in enhancing data quality,
          saving time and money, adhering to legal requirements, making better
          choices, and gaining a competitive edge.
        </p>
      </div>
      <div
        id="publishing-sites"
        className="aboutMargin"
        data-aos={"fade-up"}
        data-aos-delay={"100"}
      >
        <h3>Propriety and 3rd Party Publishing Sites</h3>
        <p>
          We have an online platform that is controlled and run by us which is a
          proprietary publishing site. Our platforms are frequently employed to
          disseminate content to a specific community, including blog posts,
          articles, and other types of media. Company websites, business blogs,
          and social media profiles are a few instances of our proprietary
          publishing websites. On the other hand, online platforms that are not
          controlled by a specific business or organization are referred to as
          third-party publishing sites. These platforms enable companies to
          share their material with a larger audience than just the users of
          their own websites and social media accounts. Online directories,
          publications tailored to a particular business, and news websites are
          a few examples of third-party publishing websites. There are many
          advantages to working with us for proprietary and third-party
          publication services.These advantages include:
        </p>
        <ul data-aos="zoom-in" data-aos-delay="100">
          <li>
            <i className="bx bx-check-double"></i>
            <b>Increased visibility and brand exposure: </b>
            Businesses can reach new audiences and increase their brand exposure
            by publishing material on third-party websites.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Better search engine rankings: </b>A business's search engine
            rankings can be improved by publishing content on our websites with
            a high domain authority, making it simpler for potential customers
            to discover them online.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Increased website traffic: </b>
            Publishing material on our websites can encourage visitors to return
            to a company's website, which can boost leads and sales.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Thought leadership and industry authority: </b>
            Regularly posting high-quality material on our independent websites
            can help you become recognized as a thought leader in your field,
            which will boost your credibility and authority.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Cost-effectiveness: </b>
            Partnering with us for proprietary and third-party publishing
            services can be an affordable way for your business to increase its
            audience and reach without having to hire more employees or allocate
            more resources.
          </li>
        </ul>
        <p data-aos={"fade-up"} data-aos-delay={"100"}>
          Proprietary and third-party publishing can, in general, be a useful
          tactic for companies seeking to improve their online exposure,
          establish their authority in their field, and increase traffic to
          their website.
        </p>
      </div>
    </div>
  );
}
