import React from "react";
import { useEffect } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import AboutUsCard from "./AboutUsCard";

function AboutUs() {
  useEffect(() => {
    Aos.init();
  }, []);

  const video = require("../../../../src/assets/images/services/Lead_Generation.png");

  return (
    <section id="about" className="services section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-style-40 aboutMargin"></div>

        <h1>Krill Technologies</h1>
        <div className="section-service-home-title">
          <p>
            Krill Technologies is delighted to share our vision, purpose, and
            values with you. Krill Technologies is committed to providing our
            customers with excellent support and dependable, high-quality
            services that are tailored to their specific requirements. Krill
            Technologies was established in 2018 with a vision to provide the
            best Lead Generation Services to our clients. To cater to the
            evolving requirements of our clients, we have expanded our services
            over time. In order to provide our clients with top-notch services,
            our team is made up of professionals who are experts in their
            respective areas.
          </p>
        </div>

        <AboutUsCard></AboutUsCard>

        <div className="row content aboutus-section-padding-16">
          <div className="" data-aos={"fade-up"} data-aos-delay={"100"}>
            <p>
              Each of our clients has specific requirements, and we cater our
              services to meet those needs. We collaborate closely with our
              clients to comprehend their needs, create solutions that are
              unique to them, and execute them expertly and precisely. Our team
              is committed to providing services that are dependable, effective,
              and economical.
            </p>
            <p>
              We work with a wide variety of clients. We put forth an additional
              effort to make sure that our clients are happy because we think
              that long-term relationships with them are important. We take
              pride in the fact that many of our clients have been with us for
              many years because they know they can rely on us to provide them
              with high-quality services that meet their requirements.
            </p>
            <p>
              Krill Technologies is dedicated to offering our clients
              high-caliber services that are tailored to their individual
              requirements. We are committed to operating our company ethically,
              creatively, collaboratively, and with a client-centric mindset. We
              are committed to establishing enduring relationships with our
              clients and providing services that go above and beyond their
              standards. We are eager to assist you and satisfy your specific
              business needs.
            </p>
            <p>
              You can also find us at Datarade{" "}
              <Link
                target={"_blank"}
                to="https://datarade.ai/data-providers/krill-technologies/profile"
              >
                Krill Technologies at Datarade
              </Link>{" "}
            </p>
          </div>

          <div
            className="footer-newsletter aboutMargin"
            data-aos={"fade-up"}
            data-aos-delay={"200"}
          >
            <h4>Would you like to know more?</h4>
            <p>
              We would love to assist you, please reach out to us at{" "}
              <Link
                target={"_blank"}
                to="https://www.linkedin.com/company/krill-tech/"
              >
                info@krilltech.com
              </Link>{" "}
            </p>
            {/* or leave out your email below, our sales team would be happy to
              connect with you via email:
            </p>
            <form
              className="col-lg-4 col-md-6"
              action=""
              method="post"
              data-aos={"zoom-in"}
              data-aos-delay={"200"}
            >
              <input type="email" name="email" />
              <input type="submit" value="Submit" />
            </form> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
