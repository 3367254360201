import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function KrillHelmet() {
  return (
    <HelmetProvider>
      <div className="krillhelmet">
        <Helmet>
          <meta charSet="utf-8" />
          <title>High Quality B2B Lead Generation and Data Services</title>
          <link rel="canonical" href="https://krilltech.com" />

          <meta
            name="description"
            content="We create unique and high-quality leads with an overall higher conversion rate that could potentially help you to amplify your ROI for sales and marketing activities"
          />

          <meta
            name="keywords"
            content="Lead Generation, Sales qualified Lead, Marketing Qualified Lead, High quality lead, Demand generation, Email marketing, Email marketing services, Sales pipeline, Inbound marketing, Sales funnel, Appointment setting services, Appointment scheduling, Data services, Data cleansing, Email campaigns, Content marketing, Digital Marketing"
          />
        </Helmet>
      </div>
    </HelmetProvider>
  );
}
