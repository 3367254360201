import React from "react";
import KrillFooter from "../../../../footer/KrillFooter";
import KrillHeader from "../../../../header/KrillHeader";
import DataServices from "./DataServices";

export default function DataServicesPage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <DataServices showDetails={true}></DataServices>
      <KrillFooter></KrillFooter>
    </div>
  );
}
