import React from "react";
import KrillFooter from "../../footer/KrillFooter";
import KrillHeader from "../../header/KrillHeader";
import PrivacyPolicy from "./PrivacyPolicy";

export default function PrivacyPage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <PrivacyPolicy></PrivacyPolicy>
      <KrillFooter></KrillFooter>
    </div>
  );
}
