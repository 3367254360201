import React from "react";
import { useEffect } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";

function KrillTeam() {
  useEffect(() => {
    Aos.init();
  }, []);

  const team3 = require("../../../assets/images/team/team-3.jpg");

  return (
    <section
      id="team"
      className="container team section-style-20"
      data-aos={"fade-up"}
    >
      <div className="section-title">
        <h2>Meet Our Management Team</h2>
        <p>
          We have a team of Industry experts with decades of b2b industry
          experience in successfully delivering the campaign with increasing
          customer satisfaction
        </p>
      </div>

      <div className="row">
        <div
          className="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos={"fade-up"}
          data-aos-delay={"100"}
        >
          <div className="member">
            <div className="member-img">
              <img src={team3} className="img-fluid" alt="Abhijeet Tiwari" />
              {/* <div className="social">
                   <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a> 
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>*/}
            </div>
            <div className="member-info">
              <h4>Abhijeet Tiwari</h4>
              <span>Founder and Managing Director</span>
            </div>
          </div>
        </div>

        <div
          className="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos={"fade-up"}
          data-aos-delay={"200"}
        >
          <div className="member">
            <div className="member-img">
              <img src={team3} className="img-fluid" alt="Nilesh Ohri" />
              {/*<div className="social">
                   <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a> 
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>*/}
            </div>
            <div className="member-info">
              <h4>Nilesh Ohri</h4>
              <span>Director of Operations</span>
            </div>
          </div>
        </div>

        <div
          className="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos={"fade-up"}
          data-aos-delay={"300"}
        >
          <div className="member">
            <div className="member-img">
              <img src={team3} className="img-fluid" alt="Albert Wood" />
              {/*<div className="social">
                 
                   <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a> 
                </div>*/}
            </div>
            <div className="member-info">
              <h4>Albert Wood</h4>
              <span>Head of Sales & Marketing</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default KrillTeam;
