import React from "react";
import { useEffect } from "react";
import Aos from "aos";

export default function ContentSyndicationDetails() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <div
        id="single-touch-email"
        className="aboutMargin"
        data-aos={"fade-up"}
        data-aos-delay={"100"}
      >
        <h3>Single Touch Email:</h3>
        <p>
          A single email is sent to a specific list of recipients as part of a
          single-touch email marketing effort to generate leads, promote a
          product or service, or increase website traffic. These emails are
          intended to be concise, aesthetically appealing, and
          conversion-focused. Working with a business that offers single-touch
          emails as a service has the following advantages:
        </p>
        <ul data-aos="zoom-in" data-aos-delay="100">
          <li>
            <i className="bx bx-check-double"></i>
            <b>Saves time and money: </b>
            By contracting with a business that specializes in email marketing,
            you can save time and money that can be put towards other aspects of
            your company.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Expertise and experience: </b>
            We an Email marketing firm have the practical knowledge to plan,
            carry out, and evaluate the results of email initiatives. We possess
            a thorough grasp of email marketing best practices and can offer
            insightful advice on how to tailor your campaigns for the best
            possible outcomes.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Cost-effective: </b>
            By eliminating the need to buy pricey email marketing software or
            employ a team of email marketing specialists, outsourcing email
            marketing to us where in we specialize can save you money and
            provide the best results.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Improved ROI: </b>
            By raising open and click-through rates, generating more leads and
            conversions, and enhancing general customer interaction, we who
            specialize in single-touch email campaigns can assist you in
            enhancing your ROI.
          </li>
          <li>
            <i className="bx bx-check-double"></i>
            <b>Customization and personalization: </b>
            We can assist you in developing tailored programs that appeal to
            your target audience and increase engagement and conversion rates.
          </li>
        </ul>
        <p data-aos={"fade-up"} data-aos-delay={"200"}>
          Working with us on single-touch emails as a service will help you
          accomplish your marketing objectives more quickly and successfully
          while freeing up time and resources to concentrate on other aspects of
          your business.
        </p>
      </div>

      <div>
        <div
          id="double-touch-email"
          className="aboutMargin"
          data-aos={"fade-up"}
          data-aos-delay={"300"}
        >
          <h3>Double Touch Email Marketing Campaign:</h3>
          <p>
            In order to boost engagement and response rates, a service known as
            "Double Touch Email Marketing Campaign" entails sending two distinct
            email messages to a specific group. Working with us for providing
            you Double Touch Email Marketing Campaign as a service could have
            the following advantages:
          </p>
          <ul data-aos="zoom-in" data-aos-delay="100">
            <li>
              <i className="bx bx-check-double"></i>
              <b>Increased engagement: </b> By sending two distinct emails, you
              raise the possibility that one of them will be acted upon by your
              audience. Higher open rates, click-through rates, and eventually
              conversions may result from this.
            </li>
            <li>
              <i className="bx bx-check-double"></i>
              <b>Improved Tracking: </b> You can more exactly target your
              audience with your messages thanks to a double-touch email
              marketing campaign. You might, for instance, send one
              communication to subscribers who have just converted and another
              to those who haven't.
            </li>
            <li>
              <i className="bx bx-check-double"></i>
              <b>Greater brand recognition: </b> You can solidify your brand and
              messaging in the thoughts of your audience by sending out multiple
              messages. Over time, this may aid in increasing knowledge and
              recognition.
            </li>
            <li>
              <i className="bx bx-check-double"></i>
              <b>Economical: </b> Double Touch Email Marketing Campaign enables
              you to get even more value out of your email campaigns. Email
              marketing is typically an economical method to reach your target
              audience.
            </li>
            <li>
              <i className="bx bx-check-double"></i>
              <b>Measurable outcome: </b> You can monitor open rates,
              click-through rates, and other metrics with email marketing to
              assess the success of your campaigns. Double Touch Email Marketing
              Campaign offers extra data points to assist you in gradually
              improving your content and targeting.
            </li>
            <li>
              <i className="bx bx-check-double"></i>
              <b>Time savings: </b> By working with us for your email marketing
              campaigns, you can give them more time to work on more crucial
              projects like product development, customer support, or sales.
            </li>
          </ul>
          <p data-aos={"fade-up"} data-aos-delay={"200"}>
            A double-touch email marketing campaign is a potent instrument for
            interacting with your audience and promoting sales. We can assist
            other businesses in achieving comparable results and forge enduring
            relationships with our clients by providing this service to them.
          </p>
        </div>

        <div
          id="inbound"
          className="aboutMargin"
          data-aos={"fade-up"}
          data-aos-delay={"300"}
        >
          <h3>Inbound Traffic With Paid Ads and SEO Services</h3>
          <p>
            Our team of professionals is highly knowledgeable and experienced in
            both SEO and sponsored advertisements. This indicates that we can
            offer you the most up-to-date methods and tactics to keep you one
            step ahead of the competition. We provide solutions that can be
            tailored to your particular business requirements. This implies that
            we can create a plan that will benefit your company and assist you
            in achieving your objectives. There are a number of advantages to
            working with us as a provider of inbound traffic through paid
            advertisements and SEO services:
          </p>
          <ul data-aos="zoom-in" data-aos-delay="100">
            <li>
              <i className="bx bx-check-double"></i>
              <b>Increased Traffic: </b>
              Using a mix of paid advertising and SEO strategies, our services
              are intended to increase traffic to your website. This will help
              your website become more visible and attract more visitors.
            </li>
            <li>
              <i className="bx bx-check-double"></i>
              <b>Higher Conversion Rates: </b>
              You can target particular audiences who are more likely to turn
              into paying customers by using our paid ads and SEO services. Your
              conversion rates will rise as a result, which will boost sales for
              your company.
            </li>
            <li>
              <i className="bx bx-check-double"></i>
              <b>Cost-Effective: </b>
              When compared to conventional forms of advertising like print or
              television advertisements, our services are more economical. They
              are therefore the perfect option for companies with modest
              marketing funds.
            </li>
            <li>
              <i className="bx bx-check-double"></i>
              <b>Cost-Effective: </b>
              When compared to conventional forms of advertising like print or
              television advertisements, our services are more economical. They
              are therefore the perfect option for companies with modest
              marketing funds.
            </li>
            <li>
              <i className="bx bx-check-double"></i>
              <b>Measurable Results: </b>
              You can monitor the performance of your campaigns with the help of
              the regular reports and analytics we offer. As a result, you can
              evaluate your investment's performance and decide how to optimize
              your marketing strategies using statistics.
            </li>
          </ul>
          <p data-aos={"fade-up"} data-aos-delay={"200"}>
            In summary, working with us to boost your inbound traffic through
            paid advertisements and SEO services can help to increase website
            traffic, boost conversion rates, and increase income for your
            company. Businesses seeking to boost their online marketing efforts
            will find us to be an ideal partner thanks to our adaptable
            solutions, knowledge, and quantifiable results.
          </p>
        </div>
      </div>
    </div>
  );
}
