import React from "react";
import { useEffect } from "react";
import Aos from "aos";
import ServicesCards from "./ServicesCards";

export default function KrillServicesHome() {
  const services = require("../../../../src/assets/images/Krill_Services.png");

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section id="services" className="services section-style-40 section-bg">
      <div className="container" data-aos="fade-up">
        <div
          className="section-service-home-title"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <h1>Our Exclusive B2B Services for all your needs</h1>
          <img src={services} className="img-fluid" alt="B2B Services" />
        </div>

        <ServicesCards></ServicesCards>
      </div>
    </section>
  );
}
