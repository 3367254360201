import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import LeadGenerationDetails from "./LeadGenerationDetails";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function LeadGeneration({ showDetails }) {
  const video = require("../../../../../assets/images/services/Lead_Generation.png");

  const [show, setShow] = useState(showDetails);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section id="lead-generation" className="about-video section-style-40">
      <HelmetProvider>
        <Helmet>
          <title>Lead Generation Service</title>
          <meta
            name="description"
            content="We specialize in offering top-notch B2B Lead Generation services
          to support the expansion and success of your business."
          />
          <meta
            name="keywords"
            content="Marketing qualified lead, Lead generationSales funnel, Lead scoring, Inbound marketing, Demand generation, Lead nurturing, Conversion rate optimization, Sales pipeline, Customer acquisition, High quality lead, Lead generation, Sales funnel, Lead scoring, Inbound marketing, Demand generation, Lead nurturing, Conversion rate optimization, Sales pipeline, Customer acquisition, Sales qualified lead, Lead generation, Sales funnel, Lead scoring, Inbound marketing, Demand generation, Lead nurturing, Conversion rate optimization, Sales pipeline, Customer acquisition"
          />
        </Helmet>
      </HelmetProvider>
      <div className="mobile-margin visible-xs"></div>

      {show && <div className="services-details-top-padding"></div>}
      <div className="container" data-aos={"fade-up"}>
        <div className="row content">
          <h1>Lead Generation Service</h1>

          {show && (
            <p className="fst-italic">
              Our organization specializes in offering top-notch leads to
              companies of all sizes and sectors. We provide a variety of
              services to assist companies to reach their target audience and
              spur growth because we recognize that producing leads may be a
              time-consuming and difficult endeavor.
            </p>
          )}

          {show && (
            <p className="fst-italic">
              We are intended to assist businesses in boosting sales and
              expanding their clientele. Search engine optimization (SEO),
              social media marketing, email marketing, and telemarketing
              services are just a few of the methods we employ to create leads.
              Together, you and our team of specialists will create a unique
              plan that is tailored to your needs and goals.
            </p>
          )}

          {show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-left"}
              data-aos-delay={"100"}
            >
              <ul data-aos="zoom-in" data-aos-delay="100">
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Enhanced efficiency:</b> We handle every step of the
                  lead-generation process, including prospect qualification,
                  lead nurturing, and client identification. Your sales team
                  will therefore have more time to concentrate on making sales
                  and expanding your company.
                </li>
                <li>
                  <i className="bx bx-check-double"></i> <b>Cost-Effective:</b>
                  Rather than attempting to handle lead creation internally, it
                  may be more economical to outsource it to us. In contrast to
                  employing and training a full-time in-house workforce, we have
                  the resources and know-how to provide high-quality leads at a
                  reduced cost.
                </li>
                <li>
                  <i className="bx bx-check-double"></i> <b>Expertise:</b> We
                  are skilled at identifying and qualifying leads in a variety
                  of sectors and industries. We communicate with potential
                  customers using the most recent technologies and strategies,
                  providing your company access to our understanding of the
                  market through Insights on every campaign closure.
                </li>
                <li>
                  <i className="bx bx-check-double"></i>{" "}
                  <b>Optimized Revenue:</b> We can increase sales and revenue
                  for your business. By generation top-quality leads and
                  nurturing them through the sales process, we will help you
                  increase your clientele and boost your company’s overall
                  profit.
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Scalability: </b>As your firm expands, so may your
                  requirement for lead generation. Depending on your changing
                  needs, we can scale up or down our services, giving you the
                  flexibility to adjust to shifting market conditions.
                </li>
              </ul>
            </div>
          )}

          {!show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-left"}
              data-aos-delay={"100"}
            >
              <p>
                We specialize in offering top-notch B2B Lead Generation services
                to support the expansion and success of your business. We
                recognize the value of producing leads for your company, and our
                skilled team is committed to providing results that boost your
                revenue and sales. We have the knowledge and resources to
                support you in achieving your objectives, whether you're
                searching for new prospects or need assistance nurturing current
                leads.
              </p>
              <p>
                Our strategy for generating leads is founded on an in-depth
                knowledge of your sector and target market. To reach the right
                people at the right moment with the right message, we employ a
                variety of strategies and tactics, including email marketing,
                social media advertising, search engine optimization, content
                marketing, and more. We take pride in providing tailored and
                efficient lead generation services that fit your unique business
                requirements and objectives. We can assist you in advancing your
                B2B lead creation efforts and achieving the success you deserve.
              </p>
              {!show && (
                <Link
                  className="btn-learn-more"
                  to={`/services/lead-generation`}
                >
                  <span>Know More</span>
                </Link>
              )}
            </div>
          )}

          <div
            className="col-lg-4 video-box align-self-baseline"
            data-aos={"fade-right"}
            data-aos-delay={"100"}
          >
            <img src={video} className="img-fluid" alt="Lead Generation" />
          </div>

          {show && <LeadGenerationDetails />}
        </div>
      </div>
    </section>
  );
}
