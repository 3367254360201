import React from "react";
import AudienceMarketResearch from "./AudienceMarketResearch";
import KrillHeader from "../../../../header/KrillHeader";
import KrillFooter from "../../../../footer/KrillFooter";

export default function AudienceMarketResearchPage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <AudienceMarketResearch showDetails={true}></AudienceMarketResearch>
      <KrillFooter></KrillFooter>
    </div>
  );
}
