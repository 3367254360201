import React from "react";
import KrillFooter from "../../../footer/KrillFooter";
import KrillHeader from "../../../header/KrillHeader";
import LeadGeneration from "./LeadGenerationPage/LeadGeneration";
import ContentSyndication from "./ContentSyndicationPage/ContentSyndication";
import AccountBasedMarketing from "./AccountBasedMarketingPage/AccountBasedMarketing";
import DataServices from "./DataServicesPage/DataServices";
import EmailMarketing from "./EmailMarketingPage/EmailMarketing";
import AudienceMarketResearch from "./AudienceMarketResearch/AudienceMarketResearch";
import KrillServices from "../KrillServices";

export default function KrillServicesPage() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <KrillServices></KrillServices>
      <LeadGeneration showDetails={false}></LeadGeneration>
      <AccountBasedMarketing showDetails={false}></AccountBasedMarketing>
      <ContentSyndication showDetails={false}></ContentSyndication>
      <EmailMarketing showDetails={false}></EmailMarketing>
      <AudienceMarketResearch showDetails={false}></AudienceMarketResearch>
      <DataServices showDetails={false}></DataServices>
      <KrillFooter></KrillFooter>
    </div>
  );
}
