import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function AccountBasedMarketing({ showDetails }) {
  const [show, setShow] = useState(showDetails);
  const video = require("../../../../../assets/images/services/Account_Based_Marketing.png");

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section
      id="account-based-marketing"
      className="about-video section-style-40 account-based-marketing-bg"
    >
      <HelmetProvider>
        <Helmet>
          <title>Account Based Marketing</title>
          <meta
            name="description"
            content="Account Based Marketing helps to find high-value accounts that are a suitable fit for your products or services, and then target those accounts specifically with your marketing initiatives"
          />
          <meta
            name="keywords"
            content="Account-based marketing, ABM strategies, ABM campaigns, Target account identification, Account-based advertising, Account-based sales, ABM technology, Account-based personalization, ABM metrics, Account-based engagement, Account-based funnel, Account-based analytics, ABM content marketing, Account-based lead generation, ABM best practices, ABM tools, Account-based segmentation, ABM ROI, 
          Account-based customer success, ABM sales enablement"
          />
        </Helmet>
      </HelmetProvider>

      <div className="mobile-margin visible-xs"></div>

      {show && <div className="services-details-top-padding"></div>}
      <div className="container" data-aos={"fade-up"}>
        <div className="row  content">
          <h1>Account Based Marketing</h1>
          {show && (
            <p className="fst-italic">
              Instead of attempting to appeal to a larger audience,
              Account-Based Marketing (ABM) focuses on particular accounts or
              businesses. With ABM, the goal is to find high-value accounts that
              are a suitable fit for your products or services, and then target
              those accounts specifically with your marketing initiatives.
            </p>
          )}
          <div
            className="col-lg-4 video-box align-self-baseline aboutMargin"
            data-aos={"fade-left"}
            data-aos-delay={"100"}
          >
            <img
              src={video}
              className="img-fluid"
              alt="Account Based Marketing"
            />
          </div>

          {show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-right"}
              data-aos-delay={"100"}
            >
              <p className="fst-italic">
                The goal of ABM is to give target accounts a more individualized
                experience in order to create a better engagement experience
                with them and enhance the likelihood that they will become
                potential customers. This may entail producing original content,
                conducting individualized outreach, and using targeted
                advertising.
              </p>
              <p className="fst-italic">
                Businesses that provide sophisticated goods or services that
                call for a more consultative sales strategy, such as those that
                have a small number of high-value clients or sell to businesses,
                frequently utilize account-based marketing (ABM).
              </p>
              <p className="fst-italic">Some benefits of ABM include:</p>
              <ul data-aos="zoom-in" data-aos-delay="200">
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Higher Conversion Rates:</b> ABM can result in higher
                  conversion rates and a shorter sales cycle by concentrating on
                  a smaller number of high-value accounts.
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Enhanced Customer Relationships:</b> You may strengthen
                  your connections with your target accounts and increase
                  customer retention by personalizing your marketing
                  initiatives.
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Enhanced ROI:</b> ABM can help you increase the return on
                  your marketing spend with a more precise focus on your
                  resources
                </li>
                <li>
                  <p>
                    Overall, ABM can be a successful strategy for businesses
                    seeking to deepen their connections with their most
                    important clients and increase their revenue from those
                    clients.
                  </p>
                </li>
              </ul>
            </div>
          )}

          {!show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-right"}
              data-aos-delay={"100"}
            >
              <p>
                We have adopted an Account Based Marketing (ABM) strategy
                because we are aware that each company is unique. Using ABM, we
                can pinpoint the businesses most likely to take advantage of our
                offerings and develop targeted campaigns that cater to their
                particular requirements. By adjusting our strategy in this
                manner, we can make sure that our message is pertinent to and
                appealing to each distinct business.
              </p>
              <p>
                Our ABM strategy is based on data-driven insights and
                individualized communications. We create incredibly targeted
                campaigns that talk directly to the requirements of our target
                accounts by utilizing a variety of tools and techniques to
                understand their needs and pain points. Look no further than us
                if you're searching for a B2B partner who has a thorough
                understanding of your industry and can provide the tailored
                solutions you require to be successful. We can assist you to
                attain the greatest outcomes with the help of our ABM approach.
              </p>
              {!show && (
                <Link
                  className="btn-learn-more"
                  to={`/services/account-based-marketing`}
                >
                  <span>Know More</span>
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
