import React from "react";
import KrillWebContent from "../content/KrillWebContent";
import KrillHeader from "../header/KrillHeader";
import KrillFooter from "../footer/KrillFooter";

function KrillApp() {
  return (
    <div>
      <KrillHeader></KrillHeader>
      <KrillWebContent></KrillWebContent>
      <KrillFooter></KrillFooter>
    </div>
  );
}

export default KrillApp;
