import React from "react";
import { useEffect } from "react";
import Aos from "aos";
import ServicesCards from "./ServicesCards";
import { Helmet, HelmetProvider } from "react-helmet-async";

function KrillServices() {
  useEffect(() => {
    Aos.init();
  }, []);

  const services = require("../../../../src/assets/images/Krill_Services.png");

  return (
    <section id="services" className="services services-top-padding section-bg">
      <HelmetProvider>
        {/* Added below for SEO */}
        <Helmet>
          <title>Exclusive B2B Services</title>
          <meta
            name="description"
            content="We specialized in providing a wide range of B2B services starting from Lead Generation, Account-based Marketing, Content Syndication, Audience & Market Research, Email Marketing, Appointment Setting, and Data Services"
          />
          <meta
            name="keywords"
            content="Lead Generation,Appointment Setting,Email Marketing, Account based Marketing,Data Services,Content Syndication, Tele Marketing"
          />
        </Helmet>
      </HelmetProvider>
      <div className="mobile-margin visible-xs"></div>
      <div className="container" data-aos="fade-up">
        <div
          className="section-service-home-title"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <h1>We offer an exclusive ecosystem of B2B Services</h1>
          <p className="aboutMargin">
            We specialize in providing the leads ranging from top of the funnel
            working our way down to the bottom. Is it just digital or mostly
            traditional? Our employee bandwidth and their skill-set allow us to
            work with both the approaches. Our strong demand generation includes
            telemarketing rock stars, digital juggernauts, and web researchers.
            Krill has successfully delivered PPC, email, SEO and Tele campaigns
            with commitment and certainty in its young time-span in this market.
          </p>
          <img src={services} className="img-fluid" alt="B2B Services" />
        </div>

        {/* <ServicesCards></ServicesCards> */}
      </div>
    </section>
  );
}

export default KrillServices;
