import React from "react";
import { useEffect } from "react";
import Aos from "aos";

export default function BlogSection() {
  useEffect(() => {
    Aos.init();
  }, []);

  const team1 = require("../../../assets/images/blog1.jpg");

  return (
    <section id="team" className="team section-bg">
      <div className="container" data-aos={"fade-up"}>
        <div className="section-title">
          <h2>Welcome to Krill</h2>
          <p>This is our exclusive blog page</p>
        </div>

        <div className="row">
          <div
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos={"fade-up"}
            data-aos-delay={"100"}
          >
            <div className="member">
              <div className="member-img">
                <img src={team1} className="img-fluid" alt="" />
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Blog 1</h4>
                <span>Some details</span>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos={"fade-up"}
            data-aos-delay={"200"}
          >
            <div className="member">
              <div className="member-img">
                <img src={team1} className="img-fluid" alt="" />
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Blog 2</h4>
                <span>Some details</span>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos={"fade-up"}
            data-aos-delay={"300"}
          >
            <div className="member">
              <div className="member-img">
                <img src={team1} className="img-fluid" alt="" />
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Blog 3</h4>
                <span>Some details</span>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos={"fade-up"}
            data-aos-delay={"400"}
          >
            <div className="member">
              <div className="member-img">
                <img src={team1} className="img-fluid" alt="" />
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Blog 4</h4>
                <span>Some details</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
