import React from "react";
import { Link } from "react-router-dom";

export default function TermsOfUse() {
  return (
    <section id="about">
      <div className="container">
        <header className="section-header-gdpr section-style-40">
          <div className="section-style-40"></div>
          <b>TERMS OF USE </b>
          <p>
            {" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            provides this website (the “Site”) subject to your compliance with
            the terms and conditions outlined in these Terms of Use (the “Site
            Terms”). By using this Site, you agree to be bound by these Site
            Terms. If you do not agree to be bound by these site terms, you must
            not use the Site.{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            may, at any time, in its sole discretion, revise or otherwise update
            these Site Terms by posing amended Site Terms on the Site and any
            changes will be effective immediately upon posting. Your use of the
            Site following the posting of updated Site Terms constitutes
            acceptance of those terms. Further,{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            reserves the right, at any time, to modify or discontinue,
            temporarily or permanently, the Site (or any part thereof) without
            notice, as set forth below.
          </p>
          <p>
            <b>INTELLECTUAL PROPERTY RIGHTS</b>
          </p>
          <b>OWNERSHIP</b>
          <p>
            All context, text, images, graphics, data, information, and other
            material displayed (as well as the original selection, arrangements,
            and presentation of such material displayed, available or present on
            this Site (“Context”), including any intellectual property rights in
            such Content (including without limitation trademarks, and
            copyrights) (hereinafter “Intellectual Property Rights”) are the
            property of{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            , its affiliates, its licensors or the designated owners, and are
            protected by copyright, trademark, and other proprietary rights and
            laws unless otherwise noted and may not be used without our written
            permission, except as otherwise provided in these Site Terms. All
            Marks on the Site that are not owned by{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            are the property of their respective owners. Nothing on the Site
            should be construed as granting, by implication, estoppel, or
            otherwise, any right or license to use any of the Marks without{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com's
            </Link>{" "}
            prior express written permission.
          </p>
          <b>AUTHORIZED AND PROHIBITED USES</b>
          <p>
            You may access, use and display this Site on a single computer only
            and download and print copies of the Content only for noncommercial,
            informational, personal use, without modification or alteration in
            any way, and only so long as you comply with these Site Terms and
            applicable laws. Except as may otherwise be expressly authorized by
            these Site Terms, you may not otherwise reproduce, sell, publish,
            distribute, transmit, modify, display use or perform or create any
            derivative works of any of this Site or the Content or Materials
            without prior written permission. If this Site permits the emailing
            of certain Content or a link through the use of an “email to a
            friend: (or similar) icon, you may send that particular Content or
            link to others by email, as indicated. You agree not to infringe
            upon any Intellectual Property Rights or remove or modify related
            property notices or remove related proprietary notices contained in
            this Site, the Materials or the Content.
          </p>
          <b>USER CONTENT</b>
          <p>
            Except as provided in our Site’s Privacy Policy, and Content that
            you submit to this Site or to use (“User Content”) will be deemed to
            be non-confidential and may be disclosed through this Site to
            unknown persons on a worldwide basis without control by{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            , including for browsing, downloading, printing and other uses by
            such other persons or entities. You agree not to submit User Content
            to this Site or to use except for Content that is fully authorized
            for purposes of this Site and these Site Terms, and you will not
            infringe upon any person’s Intellectual Property Rights in
            connection with such submissions. You should determine the extent to
            which User Content you submit is protected by applicable
            intellectual property laws. You agree that{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            shall have, and hereby grant to{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            , a worldwide, royalty-free, perpetual, irrevocable, sub-licensable,
            non-exclusive right and license to translate, reproduce, sell,
            publish, distribute, modify, adapt, display, perform, promote, link
            to or use, in any form or media, any User Content that you submit to
            this Site or us.{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            does not endorse any User Content or third-party product or service
            that may appear on this Site or to us.{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            does not endorse User Content, or third—party product or service
            that may appear on this Site. Nothing in these Site Terms shall
            obligate us to use any User Content you submit or permit the posting
            of such Content on this Site.
          </p>
          <b>REGISTRATION AND PASSWORDS</b>
          <p>
            At times, we may or may not require you to have a password and
            provide registration details to access this Site or portions of this
            Site. If we do require a password and registration details, the
            details you provide must be correct, current and complete. If we
            believe that the details are not correct, current and complete, we
            have the right to refuse you access to the Site, or any of its
            resources, and to terminate or suspend your account, if any. You are
            responsible for maintaining the confidentiality of any accounts, if
            any. You are responsible for maintaining the confidentiality of any
            password(s) you are given to access this Site or any portion
            thereof, and you are fully responsible for all activities that occur
            under your password(s). You agree to notify us immediately of any
            unauthorized use of your password(s). You reserve the absolute right
            not to issue a password to any person or entity.
          </p>
          <b>USER CONDUCT</b>
          <p>
            You agree to comply with all applicable laws and regulations in
            connection with your use of the Site. You will not violate, attempt
            to violate, or knowingly facilitate the use of the Site. You will
            not violate, attempt to violate or knowingly facilitate the
            violation of the security (including access control or
            authentication systems) or integrity of the Site. Without limiting
            the foregoing, you agree not to attempt or to enable others to
            attempt to gain unauthorized access to any other accounts, computer
            systems or networks connected to{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            .
          </p>
        </header>
      </div>
    </section>
  );
}
