import React from "react";
import AboutUs from "./aboutussection/AboutUs";
import HomeSection from "./homesection/HomeSection";
import KrillCount from "./countsection/KrillCount";
import KrillServicesHome from "./servicessection/KrillServicesHome";
import KrillTestimonials from "./testimonialsection/KrillTestmonials";
import KrillTeam from "./teamsection/KrillTeam";
import Contact from "./contactsection/Contact";
import KrillMap from "./servicessection/servicespage/MapPage/KrillMap";

function KrillWebContent() {
  return (
    <div>
      <HomeSection></HomeSection>
      <KrillServicesHome></KrillServicesHome>
      <KrillMap></KrillMap>
      <KrillCount></KrillCount>
      {/* <KrillTestimonials></KrillTestimonials> */}
      {/* <KrillTeam></KrillTeam> */}
      <Contact></Contact>
    </div>
  );
}

export default KrillWebContent;
