import React from "react";
import { useEffect } from "react";
import Aos from "aos";

export default function KrillMap() {
  const mapImg = require("../../../../../assets/images/map/Krill_Map.png");
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div
      id="krill-map"
      className="mb-4 mb-md-0 maps img-wrapper section-style-20"
    >
      {/* <div className="row justify-content-end maps-bottom hover-zoom"> */}

      <div className="map-div">
        <img
          src={mapImg}
          className="img-fluid"
          alt="Krill Technologies Industry Coverage"
        />
      </div>
    </div>
  );
}
