import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <section id="about">
      <div className="container">
        <header className="section-header-gdpr section-style-40">
          <div className="section-style-40"></div>
          <b>PRIVACY POLICY</b>
          <p>
            This privacy policy discloses the practices for{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            and applies solely to information collected by this website and sets
            out how{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            uses and protects any information when using this website. Please
            read this privacy policy carefully before using this website. Your
            information will not be shared or sold to competitors.{" "}
            <Link target={"_blank"} to="https://www.krilltech.com">
              Krilltech.com
            </Link>{" "}
            can change this policy at any time by updating this page. Visitors
            are encouraged to check this page from time to time of privacy
            policy changes.
          </p>
          <p>
            <b>INFORMATION COLLECTION, USE, AND SHARING </b>
          </p>
          <p>
            We are the sole owners of the information collected on this site. We
            only have access to/collect information that you voluntarily give us
            via email or other direct contacts from you. We will not sell or
            rent this information to anyone. We will use your information to
            respond to you, regarding the reason you contacted us. We will not
            share your information with any third party outside of our
            organization, other than as necessary to fulfill your request.
            Unless you ask us not to, we may contact you via email in the future
            to inform you about current or new services.
          </p>
          <p>
            <b>WHERE YOUR CONSENT IS REQUIRED</b>
          </p>
          <p>
            We will seek your permission if we decide to post any photographs of
            you on any of our marketing materials (including our brochures or
            website); you may withdraw your permission should you wish.
          </p>
          <p>
            <b>TRANSFER OF PERSONAL INFORMATION OUTSIDE THE COUNTRY</b>
          </p>
          <p>
            Sometimes your information will need to be transferred to and stored
            outside the EU & UK. We try to limit this, but it may be necessary
            where e.g. one of our suppliers stores your information outside
            Europe.
          </p>
          <p>
            <b>SECURITY AND ENCRYPTION</b>
          </p>
          <p>
            We take precautions to protect your information. When you submit
            sensitive information via the website, your information is protected
            both online and offline. Wherever we collect sensitive information
            including credit card data or personally identifiable data, that
            information is encrypted and securely transmitted to us. You can
            verify this by looking for a lock icon in the address bar and
            looking for “https” at the beginning of the address of the Web page.
            While we use encryption to protect sensitive information transmitted
            online, we also protect your information offline. Only employees who
            need the information to perform a specific job are granted access to
            personally identifiable information. The computers/servers in which
            we store personally identifiable information are kept in a secure
            environment.
          </p>
          <p>
            <b>HOLDING TIME OF YOUR PERSONAL INFORMATION</b>
          </p>
          <p>
            We will not keep any personal information about you for any longer
            than is necessary. We follow a personal data retention policy which
            determines how long we keep specific types of personal information.
            For further information, you can contact info@krilltech.com. For any
            data provided to us by our clients, we are governed by their data
            retention rules as specified by them.
          </p>
          <p>
            <b>COOKIE</b>
          </p>
          <p>
            The Website uses cookies for a few reasons which include improving
            the user experience.
          </p>
          <p>
            <b>LINK TO OTHER WEBSITES</b>
          </p>
          <p>
            We may publish content on our website from other sites, this privacy
            notice does not cover to other websites and organizations. We
            encourage you to read the privacy statements on the other websites
            you visit.
          </p>
          <p>
            <b>CONTACT US FOR FURTHER QUESTIONS</b>
          </p>
          <p>
            If you have any questions about this notice, then please speak to
            your project manager, sales representative. We have a Data
            Protection Co-Ordinator, Ashlesha D'souza , who can explain in more
            detail how your information is looked after. Our Data Protection
            Officer has overall responsibility for your information, if you need
            further information please email info@krilltech.com
          </p>
        </header>
      </div>
    </section>
  );
}
