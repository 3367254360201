import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import DataServicesDetails from "./DataServicesDetails";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function DataServices({ showDetails }) {
  const [show, setShow] = useState(showDetails);

  useEffect(() => {
    Aos.init();
  }, []);

  const video = require("../../../../../assets/images/services/Data_Service.png");
  return (
    <section
      id="data-services"
      className="about-video section-style-40 data-services-bg"
    >
      <HelmetProvider>
        <Helmet>
          <title>Data Services</title>
          <meta
            name="description"
            content="We offer a wholesome solution and variety of Data Services to other businesses, including Data Analysis, Data Mining, Data Cleansing, Data Enrichment, and more"
          />
          <meta
            name="keywords"
            content="Data services, Data management, Data cleansing, Data quality, Data analysis, Data enrichment, Data migration, Data integration, Data governance, Data warehousing, Big data services, Business data services, Data consulting, Data privacy, Data security, Data strategy, Data architecture, Data modeling, Data engineering, Data visualization"
          />
        </Helmet>
      </HelmetProvider>
      <div className="mobile-margin visible-xs"></div>

      {show && <div className="services-details-top-padding"></div>}
      <div className="container" data-aos={"fade-up"}>
        <div className="row content">
          <h1>Data Services</h1>
          {show && (
            <p className="fst-italic">
              We offer a wholesome solution and variety of Data Services to
              other businesses, including Data Analysis, Data Mining, Data
              Cleansing, Data Enrichment, and more. Here are some benefits of
              working with us:
            </p>
          )}

          <div
            className="col-lg-4 video-box align-self-baseline aboutMargin"
            data-aos={"fade-right"}
            data-aos-delay={"100"}
          >
            <img src={video} className="img-fluid" alt="Data Services" />
          </div>
          {show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-left"}
              data-aos-delay={"100"}
            >
              <ul data-aos="zoom-in" data-aos-delay="100">
                <li>
                  <i className="bx bx-check-double"></i>{" "}
                  <b>Data Reliability and Accuracy:</b>We make sure the
                  information we give to our clients is current, accurate, and
                  dependable so they may make more educated choices
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Improved Efficiency:</b> By contracting with us for data
                  services, organizations can save time and money that might be
                  better spent on other aspects of their operations
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Customized solutions: </b>To guarantee that each
                  organization receives the most value possible, we offer
                  individualized data solutions that are catered to their unique
                  needs
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Regulation adherence: </b>We are knowledgeable about data
                  privacy laws and make sure that all data services we offer
                  adhere to applicable rules and legislation
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Profit maximization:</b> Businesses might get a competitive
                  edge by utilizing our data services since they will have
                  access to information and insights that their competitors
                  would not
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Scalability: </b>We can work with companies of various
                  sizes, from small startups to major corporations, because our
                  services are scalable
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  <b>Expertise:</b> With years of expertise in the industry, our
                  team of data professionals is able to offer our customers
                  services of the highest caliber
                </li>
              </ul>
            </div>
          )}

          {!show && (
            <div
              className="col-lg-8 pt-3 pt-lg-0 content"
              data-aos={"fade-left"}
              data-aos-delay={"100"}
            >
              <p>
                We are experts in offering complete and trustworthy data
                services to companies across a range of sectors. Our B2B data
                services, from data acquisition to management and analysis, are
                customized to suit the specific requirements of our clients. Our
                team of skilled professionals delivers precise and useful data
                insights that promote company growth and success using
                cutting-edge technology.
              </p>
              <p>
                Data cleaning, data enrichment, data integration, data
                warehousing, data migration, data visualization, and data
                analytics are just a few of the services we offer. Our offerings
                are made to assist companies in lowering expenses, boosting
                productivity, and raising client outreach capabilities. We are
                passionate about data and take great pleasure in providing
                outstanding services that go above and beyond our client’s
                expectations. Our data services can assist your company in
                achieving its objectives.
              </p>
              <Link className="btn-learn-more" to={`/services/data-services`}>
                <span>Know More</span>
              </Link>
            </div>
          )}
          {show && <DataServicesDetails />}
        </div>
      </div>
    </section>
  );
}
